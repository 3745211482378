import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Row, Col, InputGroup, } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import Select from "react-select";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import { useNavigate, Link } from "react-router-dom";
import * as Yup from "yup";
import {
  countryListStart,
  createBeneficiaryStart,
  mobileCodesListStart,
  pingPongFieldListStart,
} from "../../store/slices/BeneficiariesSlice";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import { ButtonLoader } from "../Helper/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Skeleton from "react-loading-skeleton";
import { values } from "lodash";

const CreatePingPongBeneficiary = () => {

  const formRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation("beneficiaries.create_beneficiaries");

  const countriesLists = useSelector((state) => state.beneficiaries.countryList);
  const createBeneficiary = useSelector((state) => state.beneficiaries.createBeneficiary);
  const pingPongFieldList = useSelector((state) => state.beneficiaries.pingPongFieldList);
  const mobileCodeList = useSelector((state) => state.beneficiaries.mobileCodesList);
  const [selectedRecivingCurrency, setSelectedRecivingCurrency] = useState(null);
  const [selectedPayoutType, setSelectedPayoutType] = useState(null);
  const [recipientType, setRecipientType] = useState(null);
  const [selectedHolderType, setSelectedHolderType] = useState(null);
  const [selectedRecivingCountry, setSelectedRecivingCountry] = useState(null);
  const [currencyList, setCurrencyList] = useState(null);
  const [skipRender, setSkipRender] = useState(true);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [validationSchema, setValidationSchema] = useState(null);
  const [holderType, setHolderType] = useState([]);
  const [recipientTypes, setRecipientTypes] = useState([]);
  const [accountType, setAccountType] = useState([]);
  // const [recipientLocation, setRecipientLocation] = useState([]);
  const [occupationcode, setOccupationcode] = useState([]);
  const [fields, setFields] = useState([]);
  const profile = useSelector((state) => state.admin.profile);
  const [payoutType, setPayoutType] = useState([]);
  const [purpose, setPurpose] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const [share, setShare] = useState([{ label: "Yes", value: "Y" }, { label: "No", value: "N" }]);

  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  maxDate.setHours(23, 59, 59, 999);

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile_number.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (values?.receipentdtl_phone_no && fields.filter((data) => data.label == "receipentdtl_phone_no")[0]?.isRequired == "Y") {
      if (validateMobileNumber(values.receipentdtl_phone_no)) {
        const intlNo = formatPhoneNumberIntl(values.receipentdtl_phone_no);
        const countryCode = intlNo.substring(
          intlNo.indexOf("+") + 1,
          intlNo.indexOf(" ")
        );
        const mobile = intlNo
          .substring(intlNo.indexOf(" "), intlNo.length)
          .replaceAll(" ", "");

        dispatch(
          createBeneficiaryStart({
            ...values,
            receipentdtl_phone_no: mobile,
            receipentdtl_phone_prefix: countryCode,
          }
          )
        );
      }
    }
    else {
      dispatch(
        createBeneficiaryStart(values))
    }
  };

  useEffect(() => {
    // dispatch(countryListStart());
    dispatch(mobileCodesListStart());
  }, []);

  useEffect(() => {
    if (
      // !skipRender &&
      !countriesLists.loading &&
      Object.keys(countriesLists.data).length > 0
    ) {
      setCountryCodeList(
        countriesLists.data?.receipient_type_countries
          ?.map((country) => ({
            label: `${country.country_name} (${country.country_code})`,
            value: country.alpha_2_code,
          }))
          .filter((value, index, self) =>
            index === self.findIndex((t) => t.value === value.value) // Ensure unique values based on country alpha_2_code
          )
      );

      setHolderType(countriesLists.data?.holder_types?.map((value) => ({
        label: value,
        value: value,
      })))

      setPurpose(countriesLists.data?.purpose_of_payments && Object.entries(countriesLists.data?.purpose_of_payments)?.map(([key, value]) => ({
        label: value,
        value: key,
      })))
      setOccupationcode(countriesLists.data?.occupations && Object.entries(countriesLists.data?.occupations)?.map(([key, value]) => ({
        label: value,
        value: key,
      })))
      setRecipientTypes(countriesLists.data?.recipient_types && Object.entries(countriesLists.data?.recipient_types)?.map(([key, value]) => ({
        label: value,
        value: key,
      })))

      const uniqueCurrencies = new Set(
        countriesLists.data?.receipient_type_countries?.map(
          (data) => data.currency
        )
      );
      const currencyList = Array.from(uniqueCurrencies).map((currency) => ({
        label: currency,
        value: currency,
      }));
      setCurrencyList(currencyList);
    }
  }, [countriesLists]);

  const getCurrenciesByCountry = (data) => {
    if (data) {
      if (data.value !== selectedRecivingCountry?.value) {
        setSelectedRecivingCurrency(null);
        formRef.current.setFieldValue("bankdetail_currency", "");
      }
      formRef.current.setFieldValue("bankdetail_location", data.value);
      setSelectedRecivingCountry(data);
      setCurrencyList(
        Array.from(
          new Set(
            countriesLists.data.supported_channels
              .filter((country) =>
                country.country_code ===
                (countriesLists.data.receipient_type_countries
                  .filter((value) => data.value === value.alpha_2_code)[0]?.country_code)
              )
              .map((data) => data.currency_code)
          )
        ).map((currency_code) => ({
          label: currency_code,
          value: currency_code,
        }))
      );

    } else {
      formRef.current.setFieldValue("bankdetail_location", "");
      setSelectedRecivingCountry(null);
      setCurrencyList(
        countriesLists.data.receipient_type_countries.map((data) => ({
          label: data.currency,
          value: data.currency,
        }))
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      Object.keys(createBeneficiary.data).length > 0 &&
      !createBeneficiary.loading
    ) {
      navigate("/beneficiary-list");
    }
    setSkipRender(false);
  }, [createBeneficiary]);


  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid  #DDE1E6 !important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      color: "#757575",
      fontSize: "0.85em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0194ff"
        : state.isFocused
          ? "#f0f0f0"
          : "transparent",
      color: state.isSelected ? "#fff" : state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      }
    })
  };

  useEffect(() => {
    dispatch(
      countryListStart({
        recipient_type: 2,
      })
    );
  }, []);

  useEffect(() => {
    selectedRecivingCountry &&
      selectedRecivingCountry.value && recipientType && selectedHolderType && selectedPayoutType && selectedRecivingCurrency?.value &&
      dispatch(
        pingPongFieldListStart({
          holder_type: selectedHolderType?.value,
          payout_type: selectedPayoutType.value,
          bankdetail_currency: selectedRecivingCurrency?.value,
          bankdetail_location: selectedRecivingCountry?.value,
          receipentdtl_recipient_type: recipientType?.value
        })
      );
  }, [selectedHolderType, selectedPayoutType, selectedRecivingCurrency, recipientType]);


  useEffect(() => {
    if (
      !skipRender &&
      !pingPongFieldList.buttonDisable &&
      Object.keys(pingPongFieldList.data).length > 0
    ) {
      let fieldValues = {}
      pingPongFieldList.data.rules.forEach((field) => {
        const { label, isRequired } = field;
        if (!["receipentdtl_phone_prefix", "merchant_id", "document", "bankdetail_currency", "holder_type", "payout_type", "bankdetail_location", "receipentdtl_recipient_type", "receipentdtl_partner_user_id", "account_type"].includes(label)) {
          fieldValues = {
            ...fieldValues,
            [label]: "",
            holder_type: selectedHolderType?.value,
            payout_type: selectedPayoutType?.value,
            bankdetail_currency: selectedRecivingCurrency?.value,
            bankdetail_location: selectedRecivingCountry?.value,
            receipentdtl_recipient_type: recipientType?.value,
            account_type: "RECIPIENT_BANK"
          };
        }
      });
      formRef.current.setValues(fieldValues);
      setValidationSchema(createValidationSchema(pingPongFieldList.data.rules))
    }
    setSkipRender(false);
  }, [pingPongFieldList]);

  function handleKeyPress(event) {
    if (!/^[a-zA-Z0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }

  const createValidationSchema = (data) => {
    const schema = {};

    const updatedFields = data.map((value) => {
      const { label, isRequired } = value;
      let updatedValue = { ...value };

      switch (label) {
        case 'bankdetail_bank_account_type':
          updatedValue.type = "select";
          setAccountType([
            { label: "SAVINGS", value: "SAVINGS" },
            { label: "CURRENT", value: "CURRENT" },
            { label: "CHECKING", value: "CHECKING" }
          ]);
          schema[label] = isRequired == "Y"
            ? Yup.string().required(t("required"))
            : Yup.string().notRequired();
          break;

        case 'bankdetail_purpose_code':
          updatedValue.type = "select";
          schema[label] = isRequired == "Y"
            ? Yup.string().required(t("required"))
            : Yup.string().notRequired();
          break;

        case "bankdetail_location":
        case "receipentdtl_recipient_location":
          updatedValue.type = "select";
          schema[label] = isRequired == "Y"
            ? Yup.string().required(t("required"))
            : Yup.string().notRequired();
          break;

        case 'receipentdtl_recipient_type':
          updatedValue.type = "select";
          schema[label] = isRequired == "Y"
            ? Yup.string().required(t("required"))
            : Yup.string().notRequired();
          break;

        case 'receipentdtl_phone_no':
          updatedValue.type = "phone";
          schema[label] = isRequired == "Y"
            ? Yup.string().required(t("required"))
            : Yup.string().notRequired();
          break;

        case 'receipentdtl_period_begin':
        case 'receipentdtl_period_end':
          updatedValue.type = "date";
          schema[label] = isRequired == "Y"
            ? Yup.string().required(t("required"))
            : Yup.string().notRequired();
          break;

        case 'receipentdtl_occupation_code':
          updatedValue.type = "select";
          schema[label] = isRequired == "Y"
            ? Yup.string().required(t("required"))
            : Yup.string().notRequired();
          break;
        case 'share':
          updatedValue.type = "select";
          schema[label] = isRequired == "Y"
            ? Yup.string().required(t("required"))
            : Yup.string().notRequired();
          break;

        case 'receipentdtl_phone_prefix':
        case "bankdetail_currency":
        case "merchant_id":
          break;

        default:
          updatedValue.type = "text";
          schema[label] = isRequired == "Y"
            ? Yup.string().required(t("required"))
            : Yup.string().notRequired();
      }

      return updatedValue;
    });
    setFields(updatedFields);
    return Yup.object(schema);
  };

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area padding-btm-md">
          <div className="create-beneficiary-from-back">
            <Link to="#" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                data-name="Layer 2"
                viewBox="0 0 24 24"
              >
                <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
              </svg>
            </Link>
            <h2>{t("heading")}</h2>
          </div>
          <div className="quote-space"></div>
          <Formik
            initialValues={{
              holder_type: selectedHolderType?.value,
              payout_type: selectedPayoutType?.value,
              bankdetail_currency: selectedRecivingCurrency?.value,
              bankdetail_location: selectedRecivingCountry?.value,
              receipentdtl_recipient_type: recipientType?.value,
              account_type: "RECIPIENT_BANK"
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            innerRef={formRef}
          >
            {({ errors, touched, values, setFieldValue, setValues, resetForm }) => (
              <FORM className="create-beneficiary-from">
                <Row>
                  <Col md={12} lg={6} xl={6}>
                    {
                      <div className="recipient-from-box">
                        <div className="beneficiary-from-heading">
                          <h3>{t("region_details")}</h3>
                        </div>
                        <Row>
                          <Col sm={12} lg={12} xl={6} className="resp-mrg-btm-xs">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>
                                {t("receiving_country.name")} <span>*</span>
                              </Form.Label>
                              <Select
                                options={countryCodeList || []}
                                value={selectedRecivingCountry}
                                // isClearable
                                styles={customStyles}
                                onChange={(data) => {
                                  getCurrenciesByCountry(data);
                                }}
                                placeholder={t("receiving_country.placeholder")}
                                isSearchable={true}
                                isDisabled={!countryCodeList.length > 0}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="bankdetail_location"
                                className="text-danger text-right"
                              />
                            </Form.Group>
                          </Col>
                          {selectedRecivingCountry &&
                            <Col sm={12} lg={12} xl={6}>
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                  {t("receiving_currency.name")} <span>*</span>
                                </Form.Label>
                                <Select
                                  className="fillert-drop"
                                  value={selectedRecivingCurrency}
                                  options={selectedRecivingCountry ? currencyList : []}
                                  // isClearable
                                  styles={customStyles}
                                  onChange={(data) => {
                                    if (data) {
                                      // resetForm()
                                      formRef.current.setFieldValue("bankdetail_currency", data.value);
                                      setSelectedRecivingCurrency(data);
                                      setSelectedPayoutType(null)
                                      setPayoutType(countriesLists.data.supported_channels.filter((country) => country.currency_code == (countriesLists.data.receipient_type_countries.filter((value) => data.value == value.currency)[0]?.currency) && country.country_code == (countriesLists.data.receipient_type_countries.filter((value) => selectedRecivingCountry?.value == value.alpha_2_code)[0]?.country_code)).map((data) => ({ label: data.payment_channel, value: data.payment_channel })))


                                    } else {
                                      formRef.current.setFieldValue("bankdetail_currency", "");
                                      setSelectedRecivingCurrency(null);
                                    }
                                  }}
                                  placeholder={t(
                                    "receiving_currency.placeholder"
                                  )}
                                  isSearchable={false}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="bankdetail_currency"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>}
                          {selectedRecivingCurrency &&
                            <Col sm={12} lg={12} xl={6}>
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                  {t("recepient_type.name")} <span>*</span>
                                </Form.Label>
                                <Select
                                  className="fillert-drop"
                                  value={recipientType}
                                  options={recipientTypes || []}
                                  // isClearable
                                  styles={customStyles}
                                  onChange={(data) => {
                                    if (data) {
                                      formRef.current.setFieldValue("receipentdtl_recipient_type", data.value);
                                      setRecipientType(data)
                                    } else {
                                      formRef.current.setFieldValue("receipentdtl_recipient_type", "");
                                      setRecipientType(null)
                                    }
                                  }}
                                  placeholder={t(
                                    "recepient_type.placeholder"
                                  )}
                                  isSearchable={false}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="receipentdtl_recipient_type"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>}
                          {recipientType &&
                            <Col sm={12} lg={12} xl={6}>
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                  {t("holder_type.name")} <span>*</span>
                                </Form.Label>
                                <Select
                                  className="fillert-drop"
                                  value={selectedHolderType}
                                  options={holderType || []}
                                  // isClearable
                                  styles={customStyles}
                                  onChange={(data) => {
                                    if (data) {
                                      formRef.current.setFieldValue("holder_type", data.value);
                                      setSelectedHolderType(data)
                                    } else {
                                      setSelectedHolderType(null)
                                      formRef.current.setFieldValue("holder_type", "");
                                    }
                                  }}
                                  placeholder={t(
                                    "holder_type.placeholder"
                                  )}
                                  isSearchable={false}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="holder_type"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>}
                          {selectedHolderType &&
                            <Col sm={12} lg={12} xl={6}>
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>
                                  {t("payout_type.name")} <span>*</span>
                                </Form.Label>
                                <Select
                                  className="fillert-drop"
                                  value={selectedPayoutType}
                                  options={payoutType || []}
                                  // isClearable
                                  styles={customStyles}
                                  onChange={(data) => {
                                    if (data) {
                                      setSelectedPayoutType(data)
                                      formRef.current.setFieldValue("payout_type", data.value);
                                    } else {
                                      setSelectedPayoutType(null)
                                      formRef.current.setFieldValue("payout_type", "");
                                    }
                                  }}
                                  placeholder={t(
                                    "payout_type.placeholder"
                                  )}
                                  isSearchable={false}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="payout_type"
                                  className="text-danger"
                                />
                              </Form.Group>
                            </Col>}
                        </Row>
                      </div>
                    }
                    <div className="quote-space"></div>
                    {selectedRecivingCurrency && selectedRecivingCountry && selectedHolderType && selectedPayoutType && recipientType ? (
                      <div className="recipient-from-box">
                        {Object.keys(pingPongFieldList.data).length > 0 &&
                          <div className="beneficiary-from-heading">
                            <h3>{t("beneficiary_details")}</h3>
                          </div>}
                        <Row>
                          {pingPongFieldList.buttonDisable ? (
                            <Row>
                              {[...Array(2)].map((item, key) => (
                                <React.Fragment key={key} className="mb-3">
                                  <Col sm={12} lg={12} xl={6} className="mb-3">
                                    <Skeleton height={45} />
                                  </Col>
                                  <Col
                                    sm={12}
                                    lg={12}
                                    xl={6}
                                    key={key}
                                    className="mb-3"
                                  >
                                    <Skeleton height={45} />
                                  </Col>
                                </React.Fragment>
                              ))}
                              <Col sm={12} lg={12} xl={12} className="mb-3">
                                <Skeleton height={45} />
                              </Col>
                              <Col sm={12} lg={12} xl={12} className="mb-3">
                                <Skeleton height={45} />
                              </Col>
                            </Row>
                          ) : Object.keys(pingPongFieldList.data).length > 0 ?
                            (fields.map((value, index) => ((value.label.includes("receipentdtl") && !["receipentdtl_phone_prefix", "receipentdtl_recipient_type", "receipentdtl_partner_user_id"].includes(value.label) || value.label == "share") && (
                              value.type == "text" ?
                                <Col sm={12} lg={12} xl={6}>
                                  <Form.Group
                                    controlId="formBasicEmail"
                                    className="mb-3"
                                  >
                                    <Form.Label>
                                      {value.label == "share" ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => i > 0 && ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)}
                                      <span>{value.isRequired == "Y" && "*"}</span>
                                    </Form.Label>
                                    <Field
                                      type="text"
                                      className="form-control"
                                      name={value.label}
                                      onChange={(e) => {
                                        formRef.current?.setFieldValue(value.label, e.target.value)
                                      }}
                                      placeholder={t("placeholder", {
                                        value: `Enter ${value.label.split("_").map((value, i) =>
                                          i === 0 ? null : ` ${value.charAt(0).toUpperCase() + value.slice(1)}`
                                        ).join('')}`
                                      })}
                                      // onKeyPress={handleKeyPress}
                                    // maxLength={11}
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name={value.label}
                                      className="text-danger"
                                    />
                                  </Form.Group>
                                </Col> : value.type == "select" ?
                                  <Col sm={12} lg={12} xl={6} className="resp-mrg-btm-xs">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                      <Form.Label>
                                        {value.label == "share" ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => i > 0 && ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)} <span>{value.isRequired == "Y" && "*"}</span>
                                      </Form.Label>
                                      <Select
                                        options={value.label == "receipentdtl_recipient_location" ? countryCodeList || [] : value.label == "receipentdtl_recipient_type" ? recipientTypes || [] : value.label == "receipentdtl_occupation_code" ? occupationcode || [] : value.label == "share" ? share : []}
                                        // value={selectedRecivingCountry}
                                        // isClearable
                                        styles={customStyles}
                                        onChange={(data) => {
                                          if (data) {
                                            formRef.current?.setFieldValue(value.label, data.value)
                                          }
                                        }}
                                        placeholder={t("placeholder", {
                                          value: `Select ${value.label.split("_").map((value, i) =>
                                            i === 0 ? null : ` ${value.charAt(0).toUpperCase() + value.slice(1)}`
                                          ).join('')}`
                                        })}
                                        isSearchable={true}
                                        isDisabled={!countryCodeList.length > 0}
                                      />
                                      <ErrorMessage
                                        component={"div"}
                                        name={value.label}
                                        className="text-danger text-right"
                                      />
                                    </Form.Group>
                                  </Col>
                                  : value.type == "date" ?
                                    <Col sm={6} lg={6} xl={6}>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label className="w-100">
                                          {value.label.split("_").map((value, i) => i > 0 && ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)} <span>{value.isRequired == "Y" && "*"}</span>
                                        </Form.Label>
                                        <div className="date-sec efi-data-input">
                                          <DatePicker
                                            minDate={
                                              new Date() - 1
                                            }
                                            format="DD-MM-YYYY"
                                            onChange={(val) => {
                                              formRef.current.setFieldValue(
                                                value.label,
                                                val !== null ? JSON.stringify(new Date(val)).slice(1, 11) : ""
                                              );
                                              setSelectedDate(val);
                                            }}
                                            multiple={false}
                                            placeholder={t("placeholder", {
                                              value: `Select ${value.label.split("_").map((value, i) =>
                                                i === 0 ? null : ` ${value.charAt(0).toUpperCase() + value.slice(1)}`
                                              ).join('')}`
                                            })}
                                            className="w-100"
                                            editable={false}
                                            onOpenPickNewDate={false}
                                          />
                                        </div>
                                        <ErrorMessage
                                          component={"div"}
                                          name={value.label}
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col> : value.type == "phone" &&
                                    <Col sm={6} lg={6} xl={6}>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label className="w-100">
                                          {value.label.split("_").map((value, i) => i > 0 && ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)} <span>{value.isRequired == "Y" && "*"}</span>
                                        </Form.Label>
                                        {mobileCodeList.loading ? <div>
                                          <Skeleton height={40} width="100%" />
                                        </div> : (Object.keys(mobileCodeList.data).length > 0 && mobileCodeList.data.mobile_country_codes.length > 0) ?
                                          <InputGroup
                                            className={`register-phone-input ${errors.mobile && touched.mobile
                                              ? "input-group-error"
                                              : ""
                                              }`}
                                          >
                                            <PhoneInput
                                              name="mobile"
                                              defaultCountry={"AE"}
                                              placeholder={t("placeholder", {
                                                value: `Enter ${value.label.split("_").map((value, i) =>
                                                  i === 0 ? null : ` ${value.charAt(0).toUpperCase() + value.slice(1)}`
                                                ).join('')}`
                                              })}
                                              onChange={(data) => {
                                                formRef.current?.setFieldValue(value.label, data)
                                              }}
                                              onBlur={() => formRef.current.submitForm()}
                                              international
                                              countries={mobileCodeList.data?.mobile_country_codes}
                                              className={`${touched.mobile && errors.mobile ? "is-invalid" : ""
                                                }`}
                                              maxLength={17}
                                            />
                                          </InputGroup>
                                          : null}
                                        <ErrorMessage
                                          component={"div"}
                                          name="mobile"
                                          className="errorMsg"
                                        />
                                      </Form.Group>
                                    </Col>
                            ))
                            )) : (
                              //     <div
                              //       style={{
                              //         display: "flex",
                              //         justifyContent: "center",
                              //         alignItems: "center",
                              //         flexDirection: "column",
                              //       }}
                              //     >
                              //       <div className="data-error">
                              //       <LazyLoadImage
                              //   effect="blur"
                              //   className="retry-avater"
                              //   src={
                              //     window.location.origin +
                              //     "/img/nodata-found-img.svg"
                              //   }
                              // />
                              //   <h3>{t("please_try_again")}</h3>
                              //       </div>
                              //       <Button
                              //         className="efi-primary-btn"
                              //         type="button"
                              //         onClick={() =>
                              //           dispatch(
                              //             pingPongFieldListStart({
                              //               receipentdtl_recipient_type: recipientType,
                              //               country_code: selectedRecivingCountry.value,
                              //             })
                              //           )
                              //         }
                              //         disabled={pingPongFieldList.buttonDisable}
                              //       >
                              //         {t("retry")}
                              //       </Button>
                              //     </div>
                              ''
                            )
                          }
                        </Row>
                      </div>) : ''}
                  </Col>
                  {selectedRecivingCurrency && selectedRecivingCountry && selectedHolderType && selectedPayoutType && recipientType ? (
                    <React.Fragment>
                      <Col md={12} lg={6} xl={6}>

                        <div className="recipient-from-box">
                          {Object.keys(pingPongFieldList.data).length > 0 &&
                            <div className="beneficiary-from-heading">
                              <h3>{t("bank_details")}</h3>
                            </div>}
                          {pingPongFieldList.buttonDisable ? (
                            <Row>
                              {[...Array(2)].map((item, key) => (
                                <React.Fragment key={key} className="mb-3">
                                  <Col sm={12} lg={12} xl={6} className="mb-3">
                                    <Skeleton height={45} />
                                  </Col>
                                  <Col
                                    sm={12}
                                    lg={12}
                                    xl={6}
                                    key={key}
                                    className="mb-3"
                                  >
                                    <Skeleton height={45} />
                                  </Col>
                                </React.Fragment>
                              ))}
                              <Col sm={12} lg={12} xl={12} className="mb-3">
                                <Skeleton height={45} />
                              </Col>
                              <Col sm={12} lg={12} xl={12} className="mb-3">
                                <Skeleton height={45} />
                              </Col>
                            </Row>
                          ) : Object.keys(pingPongFieldList.data).length > 0
                            //  && pingPongFieldList.data.form_field_keys.length > 0 
                            ? (
                              <Row>
                                {fields.map((value, index) => (((value.label.includes("bankdetail") && value.label !== "bankdetail_location") || value.label == "account_type") && (
                                  value.type == "text" && value.label !== "account_type" ?
                                    <Col sm={12} lg={12} xl={6}>
                                      <Form.Group
                                        controlId="formBasicEmail"
                                        className="mb-3"
                                      >
                                        <Form.Label>
                                          {value.label.split("_").map((data, i) => (i > 0 || value.label == "account_type") && ` ${data.charAt(0).toUpperCase() + data.slice(1)}`)}
                                          <span>{value.isRequired == "Y" && "*"}</span>
                                        </Form.Label>
                                        <Field
                                          type={["bankdetail_contact_phone", "bankdetail_account_no"].includes(value.label) ? "number" : "text"}
                                          className="form-control"
                                          name={value.label}
                                          onChange={(e) => formRef.current?.setFieldValue(value.label, e.target.value)}
                                          placeholder={t("placeholder", {
                                            value: `Enter ${value.label.split("_").map((value, i) =>
                                              i === 0 ? null : ` ${value.charAt(0).toUpperCase() + value.slice(1)}`
                                            ).join('')}`
                                          })}

                                          // onKeyPress={handleKeyPress}
                                        // maxLength={11}
                                        />
                                        <ErrorMessage
                                          component={"div"}
                                          name={value.label}
                                          className="text-danger"
                                        />
                                      </Form.Group>
                                    </Col> : value.type == "select" ?
                                      <Col sm={12} lg={12} xl={6} className="resp-mrg-btm-xs">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                          <Form.Label>
                                            {value.label.split("_").map((value, i) => i > 0 && ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)} <span>{value.isRequired == "Y" && "*"}</span>
                                          </Form.Label>
                                          <Select
                                            options={value.label == "bankdetail_purpose_code" ? purpose : accountType || []}
                                            // isClearable
                                            styles={customStyles}
                                            onChange={(data) => {
                                              if (data) {
                                                formRef.current?.setFieldValue(value.label, data.value)
                                              }
                                            }}
                                            placeholder={t("placeholder", {
                                              value: `Select ${value.label.split("_").map((value, i) =>
                                                i === 0 ? null : ` ${value.charAt(0).toUpperCase() + value.slice(1)}`
                                              ).join('')}`
                                            })}
                                            isSearchable={true}
                                            isDisabled={!countryCodeList.length > 0}
                                          />
                                          <ErrorMessage
                                            component={"div"}
                                            name={value.label}
                                            className="text-danger text-right"
                                          />
                                        </Form.Group>
                                      </Col>
                                      : value.type == "date" &&
                                      <Col sm={6} lg={6} xl={6}>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Label className="w-100">
                                            {value.label.split("_").map((value, i) => i > 0 && ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)} <span>{value.isRequired == "Y" && "*"}</span>
                                          </Form.Label>
                                          <div className="date-sec efi-data-input">
                                            <DatePicker
                                              minDate={
                                                new Date() - 1
                                              }
                                              // maxDate={
                                              //   new Date(
                                              //     new Date().setHours(23, 59, 59, 999)
                                              //   )
                                              // }
                                              format="DD-MM-YYYY"
                                              onChange={(val) => {
                                                formRef.current.setFieldValue(
                                                  value.label,
                                                  val !== null ? JSON.stringify(new Date(val)).slice(1, 11) : ""
                                                );
                                                setSelectedDate(val);
                                              }}
                                              multiple={false}
                                              placeholder={t("placeholder", {
                                                value: `Select ${value.label.split("_").map((value, i) =>
                                                  i === 0 ? null : ` ${value.charAt(0).toUpperCase() + value.slice(1)}`
                                                ).join('')}`
                                              })}
                                              className="w-100"
                                              editable={false}
                                              onOpenPickNewDate={false}
                                            />
                                          </div>
                                          <ErrorMessage
                                            component={"div"}
                                            name={value.label}
                                            className="text-danger"
                                          />
                                        </Form.Group>
                                      </Col>
                                ))
                                )}
                              </Row>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="data-error">
                                  <LazyLoadImage
                                    effect="blur"
                                    className="retry-avater"
                                    src={
                                      window.location.origin +
                                      "/img/nodata-found-img.svg"
                                    }
                                  />
                                  <h3>{t("please_try_again")}</h3>
                                </div>
                                <Button
                                  className="efi-primary-btn"
                                  type="button"
                                  onClick={() =>
                                    dispatch(
                                      pingPongFieldListStart({
                                        receipentdtl_recipient_type: recipientType,
                                        country_code: selectedRecivingCountry.value,
                                      })
                                    )
                                  }
                                  disabled={pingPongFieldList.buttonDisable}
                                >
                                  {t("retry")}
                                </Button>
                              </div>
                            )}
                        </div>

                        <div className="quote-space"></div>
                      </Col>
                      <Col md={12} lg={12} className="mt-4">
                        <div className="create-beneficiary-btn-sec">
                          <Button
                            className="efi-overlay-btn"
                            onClick={() => navigate(-1)}
                          >
                            {t("cancel")}
                          </Button>
                          <Button
                            className="efi-primary-btn"
                            type="submit"
                            disabled={createBeneficiary.buttonDisable || pingPongFieldList.buttonDisable}
                          >
                            {createBeneficiary.buttonDisable ? (
                              <ButtonLoader />
                            ) : (
                              t("create")
                            )}
                          </Button>
                        </div>
                      </Col>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Col md={12} lg={6} xl={6}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <LazyLoadImage
                            effect="blur"
                            className="create-account-avater mt-3"
                            src={
                              window.location.origin +
                              "/img/create-beneficiary-img.svg"
                            }
                          />
                        </div>
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              </FORM>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default CreatePingPongBeneficiary;
