import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../../Helper/Loader";
import * as Yup from "yup";
import { businessInfoUpdateStart } from "../../../store/slices/BusinessSlice";
import { nationalitiesListStart } from "../../../store/slices/AdminSlice";
import { useNavigate } from "react-router-dom";

const BusinessInformationPingPong = (props) => {

  const formRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation("onboarding.onboarding_form.business_information");
  const navigate = useNavigate();
  const profile = useSelector((state) => state.admin.profile);
  const businessInfoUpdate = useSelector((state) => state.business.businessInfoUpdate);
  const [businessType, setBusinessType] = useState([{ label: "Company", value: "COMPANY" }, { label: "Partnership", value: "PARTNERSHIP" }, { label: "Sole Proprietorship", value: "SOLE_PROPRIETORSHIP" }])
  const [skipRender, setSkipRender] = useState(true);
  const nationalitiesList = useSelector((state) => state.admin.nationalitiesList);
  const [taxCountry, setTaxCountry] = useState([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState(businessType.find((val)=>profile.data?.user_business?.business_type==val?.value));
  const [selectedTaxCountry, setSelectedTaxCountry] = useState(null)

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #dbdbdb!important",
      borderRadius: "6px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff"
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    business_type: Yup.string()
      .required(t("required"))
      .matches(/^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/, t("operational_address_1.invalid"))
      .min(5, t("operational_address_1.min_length_invalid"))
      .notOneOf([""])
      .trim()
  });

  const handleSubmit = (values) => {
    dispatch(businessInfoUpdateStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !businessInfoUpdate.loading &&
      Object.keys(businessInfoUpdate.data).length > 0
    ) {
      props.setStep(props.step + 1);
    }
    setSkipRender(false);
  }, [businessInfoUpdate]);

  useEffect(() => {
    dispatch(nationalitiesListStart({
      all: 1,
    }));
  }, []);

  useEffect(() => {
    if (!skipRender && !nationalitiesList.buttonDisable && Object.keys(nationalitiesList.data).length > 0) {
      if (nationalitiesList.data.nationalities.length > 0) {
        setTaxCountry(nationalitiesList.data.nationalities.map((item) => ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [nationalitiesList]);

  useEffect(() => {
    if (taxCountry.length > 0) {
      setSelectedTaxCountry(taxCountry.find((item) => (item.value == profile.data.user_business.tax_id_issuance_country)))

    }
    setSkipRender(false);
  }, [taxCountry]);


  return (
    <>
      <div className="onboarding-auth billing-addres-form">
        <Row className="justify-content-between mb-3">
          <Col lg={6}>
            <div className="onboarding-auth-header onboarding-business-info-head resp-marg-zero">
              <h3>{t("header")}</h3>
              <p>{t("content")}</p>
            </div>
            <Button
              className="efi-overlay-btn"
              onClick={() => navigate("/")}
              disabled={businessInfoUpdate.buttonDisable}
            >
              {t("skip_onboarding")}
            </Button>
          </Col>
        </Row>
        <Formik
          initialValues={{
            tax_id_issuance_country:
              profile.data.user_business.tax_id_issuance_country,
            business_type: profile.data.user_business.business_type
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ setFieldValue, values, touched, errors }) => (
            <FORM
              className="efi-bank-default-form efi-onboarding-form-after-login w-100"
              style={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("business_type.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={businessType}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        setSelectedBusinessType(selectedOption)
                        setFieldValue("business_type", selectedOption.value)
                      }}
                      value={selectedBusinessType}
                      placeholder={t("business_type.placeholder")}
                      isSearchable={false}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="business_type"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("tax_id_issuance_country.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={taxCountry}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        setSelectedTaxCountry(selectedOption)
                        setFieldValue(
                          "tax_id_issuance_country",
                          // iso.whereAlpha2(selectedOption.value).alpha3
                          selectedOption.value
                        )
                      }}
                      isSearchable
                      value={selectedTaxCountry}
                      placeholder={t("tax_id_issuance_country.placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="tax_id_issuance_country"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="onboarding-auth-footer">
                <div className="onboarding-auth-footer-skip">
                  <Button
                    className="efi-overlay-btn"
                    onClick={() => props.setStep(props.step - 1)}
                    disabled={businessInfoUpdate.buttonDisable}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();  // Prevent the form submission on Enter key
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#0194ff"
                        d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"
                        data-original="#000000"
                      ></path>
                    </svg>
                    <span>{t("back")}</span>
                  </Button>
                </div>
                <div className="space-tow">
                  <Button
                    className="efi-primary-btn"
                    type="submit"
                    disabled={businessInfoUpdate.buttonDisable||nationalitiesList.buttonDisable}
                  // onClick={() => props.setStep(3)}
                  >
                    {businessInfoUpdate.buttonDisable ? (
                      <ButtonLoader varient="black" />
                    ) : (
                      t("continue")
                    )}
                  </Button>
                </div>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BusinessInformationPingPong;
