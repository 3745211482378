import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  bankListSuccess,
  bankListFailure,
  virtualAcccountLookupSuccess,
  virtualAcccountLookupFailure,
  createBeneficiarySuccess,
  createBeneficiaryFailure,
  beneficiaryListSuccess,
  beneficiaryListFailure,
  singleBeneficiaryViewSuccess,
  singleBeneficiaryViewFailure,
  updateBeneficiaryStatusSuccess,
  updateBeneficiaryStatusFailure,
  quotesListSuccess,
  quotesListFailure,
  createQuoteSuccess,
  createQuoteFailure,
  singleQuoteViewSuccess,
  singleQuoteViewFailure,
  createTransactionSuccess,
  createTransactionFailure,
  singleTransactionViewSuccess,
  singleTransactionViewFailure,
  countryListSuccess,
  countryListFailure,
  beneficiaryChartSuccess,
  beneficiaryChartFailure,
  beneficiaryTransactionListSuccess,
  beneficiaryTransactionListFailure,
  paymentPurposeListSuccess,
  paymentPurposeListFailure,
  currencyConversionSuccess,
  currencyConversionFailure,
  accountStatementsListSuccess,
  accountStatementsListFailure,
  singleTransactionDownloadSuccess,
  singleTransactionDownloadFailure,
  mobileCodesListSuccess,
  mobileCodesListFailure,
  accountStatementsDownloadSuccess,
  accountStatementsDownloadFailure,
  beneficiaryTransactionListDownloadSuccess,
  beneficiaryTransactionListDownloadFailure,
  blockUnblockBeneficiarySuccess,
  deleteBeneficiarySuccess,
  blockUnblockBeneficiaryFailure,
  deleteBeneficiaryFailure,
  pingPongFieldListSuccess,
  pingPongFieldListFailure,
} from "../slices/BeneficiariesSlice";

function* bankListAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/service_banks",
    payload: action.payload,
    successNotify: false,
    success: bankListSuccess,
    failure: bankListFailure,
  });
}

function* virtualAcccountLookupAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/virtual_account/lookup",
    payload: action.payload,
    successNotify: false,
    success: virtualAcccountLookupSuccess,
    failure: virtualAcccountLookupFailure,
  });
}

function* createBeneficiaryAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_accounts/store",
    payload: action.payload,
    success: createBeneficiarySuccess,
    failure: createBeneficiaryFailure,
  });
}

function* beneficiaryListAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_accounts",
    payload: action.payload,
    successNotify: false,
    success: beneficiaryListSuccess,
    failure: beneficiaryListFailure,
  });
}

function* singleBeneficiaryViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_accounts/show",
    payload: action.payload,
    successNotify: false,
    success: singleBeneficiaryViewSuccess,
    failure: singleBeneficiaryViewFailure,
  });
}

function* updateBeneficiaryStatusAPI(action) {
  let transactionList = yield select((state) => state.beneficiaries.beneficiaryTransactionList.data);
  let singleTransactionViewData = yield select((state) => state.beneficiaries.singleTransactionView.data);
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_transactions/status",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: (data) => {
      store.dispatch(updateBeneficiaryStatusSuccess(data));
      if (Object.keys(transactionList).length > 0) {
        transactionList = {
          ...transactionList,
          beneficiary_transactions:
            transactionList.beneficiary_transactions.map((item) =>
              item.unique_id === data.beneficiary_transaction.unique_id
                ? data.beneficiary_transaction
                : item
            ),
        };
        store.dispatch(beneficiaryTransactionListSuccess(transactionList));
      }
      if (Object.keys(singleTransactionViewData).length > 0) {
        singleTransactionViewData = {
          ...singleTransactionViewData,
          beneficiary_transaction: data.beneficiary_transaction,
          timeline: data.timeline,
        };
        store.dispatch(singleTransactionViewSuccess(singleTransactionViewData));
      }
    },
    failure: updateBeneficiaryStatusFailure,
  });
}

function* blockUnblockBeneficiaryAPI(action) {
  let beneficiaryListData = yield select((state) => state.beneficiaries.beneficiaryList.data);
  // let singleTransactionViewData = yield select((state) => state.beneficiaries.singleTransactionView.data);
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_accounts/status",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(blockUnblockBeneficiarySuccess(data));
      if (Object.keys(beneficiaryListData).length > 0) {
        beneficiaryListData = {
          ...beneficiaryListData,
          beneficiary_accounts:
            beneficiaryListData.beneficiary_accounts.map((item) =>
              item.unique_id === data.beneficiary_account.unique_id
                ? data.beneficiary_account
                : item
            ),
          // total_beneficiary_accounts: beneficiaryListData.total_beneficiary_accounts - 1,
        };
        store.dispatch(beneficiaryListSuccess(beneficiaryListData));
      }
      // if (Object.keys(singleTransactionViewData).length > 0) {
      //   singleTransactionViewData = {
      //     ...singleTransactionViewData,
      //     beneficiary_transaction: data.beneficiary_transaction,
      //     timeline: data.timeline,
      //   };
      //   store.dispatch(singleTransactionViewSuccess(singleTransactionViewData));
      // }
    },
    failure: blockUnblockBeneficiaryFailure,
  });
}

function* deleteBeneficiaryAPI(action) {
  let beneficiaryListData = yield select((state) => state.beneficiaries.beneficiaryList.data);
  // let singleTransactionViewData = yield select((state) => state.beneficiaries.singleTransactionView.data);
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_accounts/delete",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(deleteBeneficiarySuccess(data));
      if (Object.keys(beneficiaryListData).length > 0) {
        beneficiaryListData = {
          ...beneficiaryListData,
          beneficiary_accounts: beneficiaryListData.beneficiary_accounts.filter((item) => item.unique_id != data.beneficiary_account.unique_id),
          total_beneficiary_accounts: beneficiaryListData.total_beneficiary_accounts - 1,
        };
        store.dispatch(beneficiaryListSuccess(beneficiaryListData));
      }
      // if (Object.keys(singleTransactionViewData).length > 0) {
      //   singleTransactionViewData = {
      //     ...singleTransactionViewData,
      //     beneficiary_transaction: data.beneficiary_transaction,
      //     timeline: data.timeline,
      //   };
      //   store.dispatch(singleTransactionViewSuccess(singleTransactionViewData));
      // }
    },
    failure: deleteBeneficiaryFailure,
  });
}

function* quotesListAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/quotes",
    payload: action.payload,
    successNotify: false,
    success: quotesListSuccess,
    failure: quotesListFailure,
  });
}

function* createQuoteAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/quotes/store",
    payload: action.payload,
    successNotify: false,
    success: createQuoteSuccess,
    failure: createQuoteFailure,
  });
}

function* singleQuoteViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/quotes/show",
    payload: action.payload,
    successNotify: false,
    success: singleQuoteViewSuccess,
    failure: singleQuoteViewFailure,
  });
}

function* transactionListAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_transactions",
    payload: action.payload,
    successNotify: false,
    success: beneficiaryTransactionListSuccess,
    failure: beneficiaryTransactionListFailure,
  });
}

function* transactionListDownloadAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_transactions/export",
    payload: action.payload,
    successNotify: false,
    success: beneficiaryTransactionListDownloadSuccess,
    failure: beneficiaryTransactionListDownloadFailure,
  });
}

function* createTransactionAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_transactions/store",
    payload: action.payload,
    success: createTransactionSuccess,
    failure: createTransactionFailure,
  });
}

function* singleTransactionViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_transactions/show",
    payload: action.payload,
    successNotify: false,
    success: singleTransactionViewSuccess,
    failure: singleTransactionViewFailure,
  });
}

function* countryListAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/receipient_type_countries",
    payload: action.payload,
    successNotify: false,
    success: countryListSuccess,
    failure: countryListFailure,
  });
}

function* beneficiaryChartAPI(action) {
  yield getCommonSaga({
    apiUrl: "dashboard_beneficiary_chart",
    payload: action.payload,
    successNotify: false,
    success: beneficiaryChartSuccess,
    failure: beneficiaryChartFailure,
  });
}

function* paymentPurposeListAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/purpose_of_payments",
    payload: action.payload,
    successNotify: false,
    success: paymentPurposeListSuccess,
    failure: paymentPurposeListFailure,
  });
}

function* currencyConversionAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/exchange_rate",
    payload: action.payload,
    successNotify: false,
    success: currencyConversionSuccess,
    failure: currencyConversionFailure,
  });
}

function* accountStatementsListAPI(action) {
  yield getCommonSaga({
    apiUrl: "m_accounts/statements",
    payload: action.payload,
    successNotify: false,
    success: accountStatementsListSuccess,
    failure: accountStatementsListFailure,
  });
}

function* accountStatementsDownloadAPI(action) {
  yield getCommonSaga({
    apiUrl: "m_accounts/export",
    payload: action.payload,
    successNotify: false,
    success: accountStatementsDownloadSuccess,
    failure: accountStatementsDownloadFailure,
  });
}

function* singleTransactionDownloadAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_transactions/download",
    payload: action.payload,
    successNotify: false,
    success: singleTransactionDownloadSuccess,
    failure: singleTransactionDownloadFailure,
  });
}

function* mobileCodesListAPI(action) {
  yield getCommonSaga({
    apiUrl: "mobile_country_codes",
    payload: action.payload,
    successNotify: false,
    success: mobileCodesListSuccess,
    failure: mobileCodesListFailure,
  });
}

function* pingPongFieldListAPI(action) {
  yield getCommonSaga({
    apiUrl: "iban/beneficiary_accounts/rules",
    payload: action.payload,
    successNotify: false,
    success: pingPongFieldListSuccess,
    failure: pingPongFieldListFailure,
  });
}



export default function* BeneficiarySaga() {
  yield all([
    yield takeLatest("beneficiaries/bankListStart", bankListAPI),
    yield takeLatest("beneficiaries/virtualAcccountLookupStart", virtualAcccountLookupAPI),
    yield takeLatest("beneficiaries/createBeneficiaryStart",createBeneficiaryAPI),
    yield takeLatest("beneficiaries/beneficiaryListStart", beneficiaryListAPI),
    yield takeLatest("beneficiaries/singleBeneficiaryViewStart",singleBeneficiaryViewAPI),
    yield takeLatest("beneficiaries/updateBeneficiaryStatusStart",updateBeneficiaryStatusAPI),
    yield takeLatest("beneficiaries/blockUnblockBeneficiaryStart",blockUnblockBeneficiaryAPI),
    yield takeLatest("beneficiaries/deleteBeneficiaryStart", deleteBeneficiaryAPI),
    yield takeLatest("beneficiaries/quotesListStart", quotesListAPI),
    yield takeLatest("beneficiaries/createQuoteStart", createQuoteAPI),
    yield takeLatest("beneficiaries/singleQuoteViewStart", singleQuoteViewAPI),
    yield takeLatest("beneficiaries/beneficiaryTransactionListStart", transactionListAPI),
    yield takeLatest("beneficiaries/beneficiaryTransactionListDownloadStart", transactionListDownloadAPI),
    yield takeLatest("beneficiaries/createTransactionStart",createTransactionAPI),
    yield takeLatest("beneficiaries/singleTransactionViewStart", singleTransactionViewAPI),
    yield takeLatest("beneficiaries/countryListStart", countryListAPI),
    yield takeLatest('beneficiaries/beneficiaryChartStart', beneficiaryChartAPI),
    yield takeLatest('beneficiaries/paymentPurposeListStart', paymentPurposeListAPI),
    yield takeLatest('beneficiaries/currencyConversionStart', currencyConversionAPI),
    yield takeLatest('beneficiaries/accountStatementsListStart', accountStatementsListAPI),
    yield takeLatest('beneficiaries/accountStatementsDownloadStart', accountStatementsDownloadAPI),
    yield takeLatest("beneficiaries/singleTransactionDownloadStart", singleTransactionDownloadAPI),
    yield takeLatest("beneficiaries/mobileCodesListStart", mobileCodesListAPI),
    yield takeLatest("beneficiaries/pingPongFieldListStart", pingPongFieldListAPI)
  ]);
}
