import { useSelector } from "react-redux";
import PingPongVirtualAccount from "./PingPongVirtualAccount";
import StyloPayVirtualAccount from "./StyloPayVirtualAccount";


const MyComponent = () => {
    const profile = useSelector((state) => state.admin.profile);
    return (
        <>
            {profile.data.user_service_type == "pp" ?
                <PingPongVirtualAccount /> :
                <StyloPayVirtualAccount />
            }
        </>
    );
};

export default MyComponent;