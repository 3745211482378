import React, { use, useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { pingPongVirtualAccountViewStart } from "../../store/slices/AccountsSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import configuration from "react-global-configuration";

const PingPongVirtualView = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const t = useTranslation("virtual_account");
    const navigate=useNavigate()

    const pingPongVirtualAccountView = useSelector((state) => state.accounts.pingPongVirtualAccountView);

    useEffect(() => {
        dispatch(pingPongVirtualAccountViewStart({ virtual_account: id }));
    }, []);

    return (
        <>
            <div className="full-body-card-wrapped">
                <div className="pp-virtual-account-wrapped">
                    <div className="pp-table-header" onClick={()=>{navigate("/virtual-account")}}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_2"
                            width="24"
                            height="24"
                            viewBox="0 0 512 512"
                        >
                            <path d="M411.5 281h-298c-13.81 0-25-11.19-25-25s11.19-25 25-25h298c13.81 0 25 11.19 25 25s-11.19 25-25 25"></path>
                            <path d="M227.99 399.25c-6.08 0-12.18-2.21-16.99-6.67L83.5 274.33a25 25 0 0 1 .25-36.89l131-118.25c10.25-9.25 26.06-8.44 35.31 1.81s8.44 26.06-1.81 35.31l-110.72 99.94L245 355.92c10.12 9.39 10.72 25.21 1.33 35.33-4.93 5.31-11.62 8-18.34 8"></path>
                        </svg>
                        <h3> {t("account_details")}</h3>
                    </div>
                    {pingPongVirtualAccountView.loading ? (
                        <>
                            <Row>
                                <Col md={12}>
                                    {[...Array(4)].map((i, key) => (
                                        <>
                                            <Skeleton className="mb-2" key={key} width="40%" height={30} />
                                            <Skeleton className="mb-3" key={key} height={50} />
                                        </>
                                    ))}
                                </Col>
                            </Row>
                        </>
                    ) : Object.keys(pingPongVirtualAccountView?.data)?.length > 0 ? (
                        Object.keys(pingPongVirtualAccountView.data?.virtual_account)?.length > 0 ? (
                            <React.Fragment>
                                <div className="pp-view-card-row">
                                    <div className="pp-view-card">
                                        <div className="pp-card-info">
                                            <p>Account Id</p>
                                            <h4>
                                                {pingPongVirtualAccountView.data.virtual_account.account_id}
                                                {pingPongVirtualAccountView.data.virtual_account.account_id !== "N/A" &&
                                                    <CopyToClipboard onCopy={()=>getSuccessNotificationMessage("Copies")} text={pingPongVirtualAccountView.data.virtual_account.account_id}>
                                                        <Button
                                                            className="copy-btn"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="#1f73b7"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                                            >
                                                                <path
                                                                    stroke="none"
                                                                    d="M0 0h24v24H0z"
                                                                    fill="none"
                                                                />
                                                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                                            </svg>
                                                        </Button>
                                                    </CopyToClipboard>}
                                            </h4>
                                        </div>
                                        <div className="pp-card-info">
                                            <p>Account Number</p>
                                            <h4>
                                               {pingPongVirtualAccountView.data.virtual_account.account_number}
                                               {pingPongVirtualAccountView.data.virtual_account.account_number !== "N/A" &&
                                                <CopyToClipboard onCopy={()=>getSuccessNotificationMessage("Copies")} text={pingPongVirtualAccountView.data.virtual_account.account_number}>
                                                    <Button
                                                        className="copy-btn"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#1f73b7"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                                            <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                                        </svg>
                                                    </Button>
                                                </CopyToClipboard>}
                                            </h4>
                                        </div>
                                        <div className="pp-card-info">
                                            <p>Currency Type</p>
                                            <h4>
                                              {pingPongVirtualAccountView.data.virtual_account.currency}
                                            </h4>
                                        </div>
                                        <div className="pp-card-divider"></div>
                                        <div className="pp-card-info">
                                            <p>Account Holder Name</p>
                                            <h4>
                                                {pingPongVirtualAccountView.data.virtual_account.account_holder_name}
                                            </h4>
                                        </div>
                                        <div className="pp-card-info">
                                            <p>Status</p>
                                            <div className="pp-status-active">
                                               {pingPongVirtualAccountView.data.virtual_account.status}
                                            </div>
                                        </div>
                                        <div className="pp-card-info">
                                            <p>Created Date</p>
                                            <h4>
                                                {pingPongVirtualAccountView.data.virtual_account.created_at_formatted}
                                            </h4>
                                        </div>
                                        <div className="pp-card-grid-box">
                                            <div className="pp-table-header-grid">
                                                <h3>Bank Details</h3>
                                            </div>
                                            <div className="pp-card-info-grid">
                                                <p>Name</p>
                                                <h4>
                                                    {pingPongVirtualAccountView.data.virtual_account.account_bank_name}
                                                </h4>
                                            </div>
                                            <div className="pp-card-info-grid">
                                                <p>Account Type</p>
                                                <h4>
                                                    {pingPongVirtualAccountView.data.virtual_account.external_data.bank_account_type}
                                                </h4>
                                            </div>
                                            <div className="pp-card-info-grid">
                                                <p>Swift Code</p>
                                                <h4>
                                                    {pingPongVirtualAccountView.data.virtual_account.external_data.swift_code}
                                                </h4>
                                            </div>
                                            <div className="pp-card-info-grid">
                                                <p>Bank Code</p>
                                                <h4>
                                                    {pingPongVirtualAccountView.data.virtual_account.bank_code}
                                                </h4>
                                            </div>
                                            <div className="pp-card-info-grid">
                                                <p>Routing number</p>
                                                <h4>
                                                    {pingPongVirtualAccountView.data.virtual_account.external_data.routing_number}
                                                </h4>
                                            </div>

                                            <div className="pp-card-info-grid">
                                                <p>Bank Addres</p>
                                                <h4>
                                                    {pingPongVirtualAccountView.data.virtual_account.account_bank_address}
                                                </h4>
                                            </div>
                                            <div className="pp-card-info-grid">
                                                <p>Bank Country</p>
                                                <h4>
                                                    {pingPongVirtualAccountView.data.virtual_account.account_bank_country}
                                                </h4>
                                            </div>
                                            {/* <div className="pp-card-info-grid">
                                                <p>Created Date</p>
                                                <h4>
                                                    Dec 30, 2024 at 06:47 PM
                                                </h4>
                                            </div>
                                            <div className="pp-card-info-grid">
                                                <p>Created Date</p>
                                                <h4>
                                                    Dec 30, 2024 at 06:47 PM
                                                </h4>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="onboard-card">
                                    <LazyLoadImage
                                        effect="blur"
                                        className="onboarding-handle-img"
                                        src={window.location.origin + "/img/onboarding-handle.svg"}
                                    />
                                    <div className="onboard-card-info">
                                        <h5>{pingPongVirtualAccountView.data?.note}</h5>
                                        <p>{t("content") + configuration.get("configData.site_name") + t("content_complete")}</p>
                                    </div>
                                    <Link to="/user-onboarding" className="efi-overlay-btn">
                                        {t("continue")}
                                    </Link>
                                </div>
                            </React.Fragment>
                        )
                    ) : <NoDataFound />}
                </div>
            </div>
        </>
    );
};
export default PingPongVirtualView;
