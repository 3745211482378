import axios from "axios";
import { languages } from "countries-list";
import CryptoJS from "crypto-js";

export const apiUrl = "https://cms-efibank-staging.rare-able.com/api/user/"; // Production Mode

const signContent = (url, body, apiKey) => {
    let stringifiedObj = {};
    if (url !== "kyb_submit") {
        for (const key in body) {
            if (Object.hasOwnProperty.call(body, key) && !('File' in window && body[key] instanceof File)) {
                if (body[key] && typeof body[key] === 'object' && !(body[key] instanceof File)) {

                    stringifiedObj[key] = String(JSON.stringify(body[key]));
                } else {

                    stringifiedObj[key] = String(body[key]).trim();
                }
            }
        }
    }
    else {
        const transformPayload = (body) => {
            const output = {
                location: body.location,
                merchant_ack: body.merchant_ack,
                merchant_type: body.merchant_type,
                company: {
                    business_person: [],
                    enterprise: {}
                },
                language: body.language
            };

            let index = 0;
            while (body[`company[business_person][${index}][first_name]`]) {
                let person = {};
                Object.keys(body).forEach(key => {
                    const match = key.match(new RegExp(`company\\[business_person\\]\\[${index}\\]\\[(.*?)\\]`));
                    if (match) {
                        person[match[1]] = body[key].trim();
                    }
                });
                output.company.business_person.push(person);
                index++;
            }

            Object.keys(body).forEach(key => {
                const match = key.match(/company\[enterprise\]\[(.*?)\]/);
                if (match) {
                    const newKey = match[1];
                    if (newKey.startsWith('account_usage')) {
                        if (!output.company.enterprise.account_usage) {
                            output.company.enterprise.account_usage = [];
                        }
                        output.company.enterprise.account_usage.push(body[key].trim());
                    } else {
                        output.company.enterprise[newKey] = body[key].trim();
                    }
                }
            });

            return output;
        };
        stringifiedObj = transformPayload(body);
    }
    const plainContent = `/${url.substring(url.lastIndexOf('/') + 1)}${body ? JSON.stringify(stringifiedObj) : "{}"}${localStorage.getItem("salt_key") || sessionStorage.getItem("salt_key")}`;

    return CryptoJS.HmacSHA256(plainContent, apiKey).toString(CryptoJS.enc.Hex);
}

const Environment = {
    postMethod: async (action, object, baseUrl = apiUrl) => {
        const url = baseUrl + action;

        let formData = new FormData();

        for (let key in object) {
            if (object.hasOwnProperty(key)) {

                formData.append(key, object[key]);

            }
        }

        const accessToken = localStorage.getItem("token") ?
            localStorage.getItem("token")
            : sessionStorage.getItem("token") ?
                sessionStorage.getItem("token")
                : "";

        const lang = localStorage.getItem("lang") || "en"
        formData.append("language", lang)

        //signature generation
        const apiKey = (localStorage.getItem('api_key') || sessionStorage.getItem('api_key'));
        let signature = "";
        if (apiKey) {
            signature = signContent(action, { ...object, language: lang }, apiKey);
        }
        // let config = { headers: { Authorization: 'Bearer ' + accessToken, 'X-Api-Key': ((localStorage.getItem('api_key') || sessionStorage.getItem('api_key'))) || "", 'X-Api-Signature': signature, "ngrok-skip-browser-warning": "69420", } };
        let config = { headers: { Authorization: 'Bearer ' + accessToken, 'X-Api-Key': ((localStorage.getItem('api_key') || sessionStorage.getItem('api_key'))) || "", 'X-Api-Signature': signature } };

        return await axios.post(url, formData, config);
    },
    getMethod: async (action, object) => {
        let userId =
            localStorage.getItem("userId") !== "" &&
                localStorage.getItem("userId") !== null &&
                localStorage.getItem("userId") !== undefined
                ? localStorage.getItem("userId")
                : "";
        let accessToken =
            localStorage.getItem("accessToken") !== "" &&
                localStorage.getItem("accessToken") !== null &&
                localStorage.getItem("accessToken") !== undefined
                ? localStorage.getItem("accessToken")
                : "";

        const url = apiUrl + action;

        let formData = new FormData();

        // By Default Id and token

        formData.append("id", userId);
        formData.append("token", accessToken);

        // append your data
        for (var key in object) {
            formData.append(key, object[key]);
        }

        // By Default added device type and login type in future use

        // formData.append("login_by", LOGIN_BY);
        // formData.append("device_type", DEVICE_TYPE);
        // formData.append("device_token", DEVICE_TOKEN);
        // let config = { headers: { "ngrok-skip-browser-warning": true, } };

        return await axios.get(url, formData);
    },
};

export default Environment;
