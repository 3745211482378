import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Form, Image } from "react-bootstrap";
import Select from 'react-select';
import { Formik, Form as FORM, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createPingPongVirtualAccountStart, pingPongVirtualAccountStart, virtualAccountCountryListStart } from "../../store/slices/AccountsSlice";
import { ButtonLoader } from '../Helper/Loader';
import iso from "iso-3166-1"

const PingPongVirtualAccountAdd = (props) => {
    const pingPongVirtualAccount = useSelector((state) => state.accounts.pingPongVirtualAccount);
    const [currencyList, setCurrencyList] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [skipRender, setSkipRender] = useState(true);
    const dispatch = useDispatch();
    const createPingPongVirtualAccount = useSelector((state) => state.accounts.createPingPongVirtualAccount);
    const virtualAccountCountryList = useSelector((state) => state.accounts.virtualAccountCountryList);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [country, setCountry] = useState([]);

    const customStyles = {
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            left: "0px",
            borderRadius: "8px",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            minWidth: 250,
            fontSize: "0.85em",
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "3px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "3px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
                backgroundColor: "#555",
            },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent!important",
            border: "1px solid #E7E7E7!important",
            borderRadius: "8px!important",
            boxShadow: "none!important",
            height: "45px",
            minWidth: "150px",
            cursor: "pointer",
            fontSize: "0.9em",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#757575",
            fontSize: "0.9em",
            fontWeight: "400",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#1c1c1c",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            fontSize: "0.9em",
            fontWeight: "400",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            color: "#1c1c1c!important",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#111",
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
            color: state.isFocused || state.isSelected ? '#fff' : '#000',
            ":hover": {
                backgroundColor: "#0194ff",
                color: "#fff"
            },
        }),
    };

    useEffect(() => {
        dispatch(virtualAccountCountryListStart())
    }, [])

    useEffect(() => {
        if (!skipRender && !createPingPongVirtualAccount.loading && Object.keys(createPingPongVirtualAccount.data).length > 0) {
            props.onHide();
            dispatch(pingPongVirtualAccountStart());
        }
        setSkipRender(false);
    }, [createPingPongVirtualAccount])

    const getExcludedCountries = (data) => {
        return pingPongVirtualAccount.data.virtual_accounts.filter(account => account?.country == data?.toLowerCase());
    };

    const handleCountryChange = (countryCode) => {
        setSelectedCountry(countryCode);
        setSelectedCurrency(null)

        const availableCurrencies = virtualAccountCountryList.data[countryCode.value] || [];
        const excludedCountries = getExcludedCountries(countryCode.value)
        const validCurrencies = availableCurrencies.filter(currency => excludedCountries.every(data => data.currency !== currency));

        setCurrencyList(validCurrencies.map((data) => ({
            label: data,
            value: data
        })));
    };
  
    useEffect(() => {
        if (!skipRender && !virtualAccountCountryList.loading && Object.keys(virtualAccountCountryList.data).length > 0) {
            const filterCountries = (countryList, virtualAccounts) => {
                let result = [];
                for (const countryCode in countryList) {
                    const countryCurrencies = countryList[countryCode];

                    const virtualCurrencies = virtualAccounts
                        .filter(account => account.country == countryCode.toLocaleLowerCase())
                        .map(account => account.currency);

                    const missingCurrencies = countryCurrencies.filter(currency => !virtualCurrencies.includes(currency));

                    if (missingCurrencies.length > 0) {
                        result.push({ label: iso.whereAlpha2(countryCode)?.country, value: countryCode });
                    }
                }

                return result;
            }
            const filteredCountries = filterCountries(virtualAccountCountryList.data, pingPongVirtualAccount.data.virtual_accounts);
            setCountry(filteredCountries);
        }

        setSkipRender(false);
    }, [virtualAccountCountryList]);


    const handleSubmit = (values) => {
        dispatch(createPingPongVirtualAccountStart(values));
    }
    const validationSchema = Yup.object().shape({
        currency: Yup.string().required('Currency is required'),
    })

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="pt-0">
                <div className="create-beneficiary-form-heading mb-3">
                    <Image
                        className="bank-logo-orange"
                        src={window.location.origin + "/img/beneficiary.svg"}
                        type="image/png"
                    />
                </div>
                <h3 className="change-password-heading mb-4 text-center">Account Details</h3>
                <Formik
                    initialValues={{
                        currency: "",
                        country: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue, }) => (
                        <FORM className="payament-form-input">
                            <div className="efi-bank-default-form">
                                <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label">
                                        Country
                                    </Form.Label>
                                    <Select
                                        options={country}
                                        styles={customStyles}
                                        isSearchable={false}
                                        isDisabled={createPingPongVirtualAccount.buttonDisable}
                                        value={selectedCountry}
                                        onChange={(data) => {
                                            setFieldValue("country", data?.value)
                                            handleCountryChange(data)
                                        }}
                                        placeholder={
                                            <div className="placeholder-flex">
                                                Select
                                            </div>
                                        }
                                    />
                                </Form.Group>
                                {selectedCountry &&
                                    <Form.Group className="mb-3">
                                        <Form.Label className="default-form-label">
                                            Currency
                                        </Form.Label>
                                        <Select
                                            options={currencyList}
                                            styles={customStyles}
                                            isSearchable={false}
                                            isDisabled={createPingPongVirtualAccount.buttonDisable}
                                            value={selectedCurrency}
                                            onChange={(selectedOption) => {
                                                setSelectedCurrency(selectedOption);
                                                setFieldValue("currency", selectedOption.value)
                                            }}
                                            placeholder={
                                                <div className="placeholder-flex">
                                                    Select
                                                </div>
                                            }
                                        />
                                    </Form.Group>}
                                <ErrorMessage
                                    className="errorMsg"
                                    name='currency'
                                    component='div'
                                />
                                {/* <div className="pp-file-upload-box">
                            <Form.Group className="mb-3">
                                <Form.Label className="default-form-label">
                                    Step 1 - Front Side
                                </Form.Label>
                                <div className="pp-file-card">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Drop file or <span> Browse </span></p>
                                        <h5>Format: pdf, png, jpg & Max file size: 25 MB</h5>
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="default-form-label">
                                    Step 2 - Back Side
                                </Form.Label>
                                <div className="pp-file-card">
                                    <div className="pp-file-preview">
                                        <h5>File Name</h5>
                                        <Button>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlSpace="preserve"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <g fill="red">
                                                    <path
                                                        d="M21 4h-3.1A5.01 5.01 0 0 0 13 0h-2a5.01 5.01 0 0 0-4.9 4H3a1 1 0 0 0 0 2h1v13a5.006 5.006 0 0 0 5 5h6a5.006 5.006 0 0 0 5-5V6h1a1 1 0 0 0 0-2M11 2h2a3.01 3.01 0 0 1 2.829 2H8.171A3.01 3.01 0 0 1 11 2m7 17a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V6h12Z"
                                                        data-original="#000000"
                                                    ></path>
                                                    <path
                                                        d="M10 18a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1m4 0a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1"
                                                        data-original="#000000"
                                                    ></path>
                                                </g>
                                            </svg>
                                        </Button>
                                    </div>
                                </div>
                            </Form.Group>
                        </div> */}
                            </div>
                            <div className="payment-form-footer">
                                <Button className="efi-primary-btn w-100" type='submit' disabled={createPingPongVirtualAccount.buttonDisable}>
                                    {createPingPongVirtualAccount.buttonDisable ?
                                        <ButtonLoader /> : "Create Account"}
                                </Button>
                            </div>
                        </FORM>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
}

export default PingPongVirtualAccountAdd;