import { useSelector } from "react-redux";
import CreatePingPongBeneficiary from "./CreatePingPongBeneficiary";
import CreateStylopayBeneficiary from "./CreateStylopayBeneficiary";


const MyComponent = () => {
    const profile = useSelector((state) => state.admin.profile);
    return (
        <>
            {profile.data.user_service_type == "pp" ?
                <CreatePingPongBeneficiary /> :
                <CreateStylopayBeneficiary />
            }
        </>
    );
};  


export default MyComponent;