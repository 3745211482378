import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  businessInfoUpdateFailure,
  businessInfoUpdateSuccess,
  businessUserStoreFailure,
  businessUserStoreSuccess,
  businessUserListFailure,
  businessUserListSuccess,
  businessUserShowFailure,
  businessUserShowSuccess,
  updateBusinessDocumentSuccess,
  updateBusinessDocumentFailure,
  businessDocumentsInfoSuccess,
  businessDocumentsInfoFailure,
  businessDocumentsStatusSuccess,
  businessDocumentsStatusFailure,
  personalDocumentsStatusSuccess,
  personalDocumentsStatusFailure,
  businessTransactionsListSuccess,
  businessTransactionsListFailure,
  businessTransactionStoreSuccess,
  businessTransactionStoreFailure,
  businessUserEditSuccess,
  businessUserEditFailure,
  businessUserDeleteSuccess,
  businessUserDeleteFailure,
} from "../slices/BusinessSlice";
import { profileSuccess } from "../slices/AdminSlice";

function* businessInfoUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_business",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(businessInfoUpdateSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: businessInfoUpdateFailure,
  });
}

function* businessUserStoreAPI(action) {
  yield getCommonSaga({
    apiUrl: "sub_users/store",
    payload: action.payload,
    successNotify: true,
    success: businessUserStoreSuccess,
    failure: businessUserStoreFailure,
  });
}

function* businessUserListAPI(action) {
  yield getCommonSaga({
    apiUrl: "sub_users",
    payload: action.payload,
    successNotify: false,
    success: businessUserListSuccess,
    failure: businessUserListFailure,
  });
}

function* businessUserShowAPI(action) {
  yield getCommonSaga({
    apiUrl: "sub_users/show",
    payload: action.payload,
    successNotify: false,
    success: businessUserShowSuccess,
    failure: businessUserShowFailure,
  });
}

function* updateBusinessDocumentAPI(action) {
  const businessDocumentsInfoData = yield select(state => state.business.businessDocumentsInfo.data);
  yield getCommonSaga({
    apiUrl: "update_business_document",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(updateBusinessDocumentSuccess(data));
      store.dispatch(businessDocumentsInfoSuccess({
        ... businessDocumentsInfoData,
        kyb_documents:data?.kyb_documents
      }));
    },
    failure: updateBusinessDocumentFailure,
  });
}

function* businessDocumentsInfoAPI(action) {
  yield getCommonSaga({
    apiUrl: "business_documents",
    payload: action.payload,
    successNotify: false,
    success: businessDocumentsInfoSuccess,
    failure: businessDocumentsInfoFailure,
  });
}

function* businessDocumentsStatusAPI(action) {
  yield getCommonSaga({
    apiUrl: "business_documents_status",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    errorNotify: false,
    success: (data) => {
      store.dispatch(businessDocumentsStatusSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: businessDocumentsStatusFailure,
  });
}

function* personalDocumentsStatusAPI(action) {
  yield getCommonSaga({
    apiUrl: "kyc_status",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    errorNotify: false,
    success: (data) => {
      store.dispatch(personalDocumentsStatusSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: personalDocumentsStatusFailure,
  });
}

function* businessTransactionStoreAPI(action) {
  const businessTransactionsList = yield select((state) => state.business.businessTransactionsList.data);
  yield getCommonSaga({
    apiUrl: "transactions/store",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(businessTransactionStoreSuccess(data));
      if (Object.keys(businessTransactionsList).length > 0)
        store.dispatch(
          businessTransactionsListSuccess({
            m_transactions: [data.transaction,...businessTransactionsList.transactions],
            total_transactions: businessTransactionsList.total_transactions - 1,
          })
        );
    },
    failure: businessTransactionStoreFailure,
  });
}

function* businessTransactionsListAPI(action) {
  yield getCommonSaga({
    apiUrl: "transactions",
    payload: action.payload,
    successNotify: false,
    success: businessTransactionsListSuccess,
    failure: businessTransactionsListFailure,
  });
}

function* businessUserEditAPI(action) {
  const businessUserListData = yield select((state) => state.business.businessUserList.data);
  yield getCommonSaga({
    apiUrl: "sub_users/update",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(businessUserEditSuccess(data));
      if (Object.keys(businessUserListData).length > 0)
        store.dispatch(
          businessUserListSuccess({
            users: businessUserListData.users.map(item => item.user_id == data.user.user_id ? data.user : item),
            total_users: businessUserListData.total_users - 1,
          })
        );
    },
    failure: businessUserEditFailure,
});
}

function* businessUserDeleteAPI(action) {
  const businessUserListData = yield select((state) => state.business.businessUserList.data);
  yield getCommonSaga({
    apiUrl: "sub_users/delete",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(businessUserDeleteSuccess(data));
      if (Object.keys(businessUserListData).length > 0)
        store.dispatch(
          businessUserListSuccess({
            users: businessUserListData.users.filter(item => item.user_unique_id != data.user_unique_id),
            total_users: businessUserListData.total_users - 1,
          })
        );
    },
    failure: businessUserDeleteFailure,
  });
}

export default function* BusinessSaga() {
  yield all([
    yield takeLatest("business/businessInfoUpdateStart", businessInfoUpdateAPI),
    yield takeLatest("business/businessUserStoreStart", businessUserStoreAPI),
    yield takeLatest("business/businessUserEditStart", businessUserEditAPI),
    yield takeLatest("business/businessUserDeleteStart", businessUserDeleteAPI),
    yield takeLatest("business/businessUserListStart", businessUserListAPI),
    yield takeLatest("business/businessUserShowStart", businessUserShowAPI),
    yield takeLatest("business/updateBusinessDocumentStart", updateBusinessDocumentAPI),
    yield takeLatest("business/businessDocumentsInfoStart",businessDocumentsInfoAPI),
    yield takeLatest("business/businessDocumentsStatusStart",businessDocumentsStatusAPI),
    yield takeLatest("business/personalDocumentsStatusStart",personalDocumentsStatusAPI),
    yield takeLatest("business/businessTransactionStoreStart", businessTransactionStoreAPI),
    yield takeLatest("business/businessTransactionsListStart", businessTransactionsListAPI),
  ]);
}
