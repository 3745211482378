import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
    accountsListFailure,
    accountsListSuccess,
    accountsStoreFailure,
    accountsStoreSuccess,
    accountsViewFailure,
    accountsViewSuccess,
    accountsStatementsFailure,
    accountsStatementsSuccess,
    contactsListSuccess,
    contactsListFailure,
    contactsStoreSuccess,
    contactsViewSuccess,
    contactsStoreFailure,
    contactsViewFailure,
    contactsDeleteSuccess,
    contactsDeleteFailure,
    allContactsListSuccess,
    allContactsListFailure,
    findUserSuccess,
    findUserFailure,
    virtualAccountSuccess,
    virtualAccountFailure,
    createPingPongVirtualAccountSuccess,
    createPingPongVirtualAccountFailure,
    virtualAccountCountryListSuccess,
    virtualAccountCountryListFailure,
    pingPongVirtualAccountSuccess,
    pingPongVirtualAccountFailure,
    pingPongVirtualAccountViewSuccess,
    pingPongVirtualAccountViewFailure,
} from "../slices/AccountsSlice";

function* accountsListAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_accounts",
        payload: action.payload,
        success: accountsListSuccess,
        failure: accountsListFailure,
    });
}

function* accountsStoreAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_accounts/store",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(accountsStoreSuccess(data));
            store.dispatch(accountsViewSuccess(data));
        },
        failure: accountsStoreFailure,
    });
}

function* accountsViewAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_accounts/show",
        payload: action.payload,
        successNotify: false,
        success: accountsViewSuccess,
        failure: accountsViewFailure,
    });
}

function* accountsStatementsAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_transactions/export",
        payload: action.payload,
        success: accountsStatementsSuccess,
        failure: accountsStatementsFailure,
    });
}

function* contactsListAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_contacts",
        payload: action.payload,
        successNotify: false,
        success: contactsListSuccess,
        failure: contactsListFailure,
    });
}

function* contactsStoreAPI(action) {
    const contactsListData = yield select(state => state.accounts.contactsList.data);
    yield getCommonSaga({
        apiUrl: "m_contacts/store",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(contactsStoreSuccess(data));
            if (Object.keys(contactsListData).length > 0)
                store.dispatch(contactsListSuccess({
                    m_contacts: [data.m_contact, ...contactsListData.m_contacts],
                    total_m_contacts: contactsListData.total_m_contacts + 1,
                }))
        },
        failure: contactsStoreFailure,
    });
}

function* contactsViewAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_contacts/show",
        payload: action.payload,
        successNotify: false,
        success: contactsViewSuccess,
        failure: contactsViewFailure,
    });
}

function* contactsDeleteAPI(action) {
    const contactsListData = yield select(state => state.accounts.contactsList.data);
    yield getCommonSaga({
        apiUrl: "m_contacts/delete",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(contactsDeleteSuccess(data));
            if (Object.keys(contactsListData).length > 0)
                store.dispatch(contactsListSuccess({
                    m_contacts: [
                        ...contactsListData.m_contacts.filter(item => item.unique_id !== action.payload.m_contact_unique_id),
                    ],
                    total_m_contacts: contactsListData.total_m_contacts - 1,
                }))
        },
        failure: contactsDeleteFailure,
    });
}

function* allContactsListAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_contacts",
        payload: action.payload,
        successNotify: false,
        success: allContactsListSuccess,
        failure: allContactsListFailure,
    });
}

function* findUserAPI(action) {
    yield getCommonSaga({
        apiUrl: "m_accounts/find_user",
        payload: action.payload,
        successNotify: false,
        success: findUserSuccess,
        failure: findUserFailure,
    });
}

function* virtualAccountAPI(action) {
    yield getCommonSaga({
        apiUrl: "virtual_account",
        payload: action.payload,
        successNotify: false,
        success: virtualAccountSuccess,
        failure: virtualAccountFailure,
    });
}

function* createPingPongVirtualAccountAPI(action) {
    yield getCommonSaga({
        apiUrl: "iban/virtual_accounts/create",
        payload: action.payload,
        successNotify: true,
        success: createPingPongVirtualAccountSuccess,
        failure: createPingPongVirtualAccountFailure,
    });
}
function* virtualAccountCountryListAPI(action) {
    yield getCommonSaga({
        apiUrl: "iban/virtual_accounts/lookup",
        payload: action.payload,
        successNotify: false,
        success: virtualAccountCountryListSuccess,
        failure: virtualAccountCountryListFailure,
    });
}

function* pingPongVirtualAccountViewAPI(action) {
    yield getCommonSaga({
        apiUrl: "iban/virtual_accounts/show",
        payload: action.payload,
        successNotify: false,
        success: pingPongVirtualAccountViewSuccess,
        failure: pingPongVirtualAccountViewFailure,
    });
}

function*pingPongVirtualAccountAPI(action) {
    yield getCommonSaga({
        apiUrl: "iban/virtual_accounts",
        payload: action.payload,
        successNotify: false,
        success:pingPongVirtualAccountSuccess,
        failure:pingPongVirtualAccountFailure,
    });
}

export default function* AccountsSaga() {
    yield all([
        yield takeLatest('expense/accountsListStart', accountsListAPI),
        yield takeLatest('expense/accountsStoreStart', accountsStoreAPI),
        yield takeLatest('expense/accountsViewStart', accountsViewAPI),
        yield takeLatest('expense/accountsStatementsStart', accountsStatementsAPI),
        yield takeLatest('expense/contactsListStart', contactsListAPI),
        yield takeLatest('expense/contactsStoreStart', contactsStoreAPI),
        yield takeLatest('expense/contactsViewStart', contactsViewAPI),
        yield takeLatest('expense/contactsDeleteStart', contactsDeleteAPI),
        yield takeLatest('expense/allContactsListStart', allContactsListAPI),
        yield takeLatest('expense/findUserStart', findUserAPI),
        yield takeLatest('expense/virtualAccountStart', virtualAccountAPI),
        yield takeLatest('expense/createPingPongVirtualAccountStart',createPingPongVirtualAccountAPI),
        yield takeLatest('expense/virtualAccountCountryListStart',virtualAccountCountryListAPI),
        yield takeLatest('expense/pingPongVirtualAccountViewStart',pingPongVirtualAccountViewAPI),
        yield takeLatest('expense/pingPongVirtualAccountStart',pingPongVirtualAccountAPI),
    ]);
}