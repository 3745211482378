import React, { useRef, useState, useEffect } from "react";
import {
  Image,
  Button,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import isEqual from "lodash/isEqual";
import { countriesListStart, mobileCodeListStart, nationalitiesListStart, updateOnboardingDetailsStart, updatePersonalInformationStart } from "../../store/slices/AdminSlice";
import Select, { components } from "react-select";
import { countries } from "countries-list";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import { ButtonLoader } from "../Helper/Loader";
import NoDataFound from "../Helper/NoDataFound";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";
import DatePicker from "react-multi-date-picker";

const IndividualAccountDetails = (props) => {

  const formRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation("settings.setting");

  const profile = useSelector((state) => state.admin.profile);
  const updateOnboardingDetails = useSelector((state) => state.admin.updateOnboardingDetails);
  const nationalitiesList = useSelector((state) => state.admin.nationalitiesList);
  const countriesList = useSelector((state) => state.admin.countriesList);
  const mobileCodeList = useSelector((state) => state.admin.mobileCodeList);

  const [skipRender, setSkipRender] = useState(true);
  const [deliverySameAsBilling, setDeliverySameAsBilling] = useState(false);
  const [close, setClose] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState([])
  const [countryOptions, setCountryOptions] = useState([])
  const [nationalitiesOptions, setNationalitiesOptions] = useState([])

  const title_options = [
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("title.required")),
    first_name: Yup.string()
      .required(t("name.required"))
      .matches(/^\S.*$/, t("name.required"))
      .matches(/^[A-Za-z\s]+$/, t("name.invalid"))
      .min(3, t("name.min_length_invalid"))
      .trim(),
    middle_name: Yup.string()
      .matches(/^\S.*$/, t("name.required"))
      .matches(/^[A-Za-z\s]+$/, t("name.invalid"))
      .min(3, t("name.min_length_invalid"))
      .trim(),
    last_name: Yup.string()
      .required(t("name.required"))
      .matches(/^[A-Za-z\s]+$/, t("name.invalid"))
      .min(1, t("last_name.min_length_invalid"))
      .trim(),
    email: Yup.string()
      .email(t("email.invalid"))
      .required(t("email.required"))
      .min(12, t("email.min_length_invalid"))
      .max(255, t("email.max_length_invalid")),
    dob: Yup.string().required(t("required")),
    mobile: Yup.string()
      .required(t("mobile.required"))
      .matches(/^(?=.*[0-9])/, t("mobile.invalid"))
      .min(10, t("mobile.min_length_invalid"))
      .max(15, t("mobile.max_length_invalid")),
    gender: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    nationality: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    billing_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_address_1.invalid"))
      .min(5, t("required_note"))
      .trim(),
    billing_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_address_2.invalid"))
      .min(5, t("required_note"))
      .trim(),
    billing_city: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Billing city" }))
      .min(3, t("min_length_invalid"))
      .trim(),
    billing_state: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Billing state" }))
      .min(2, t("state_length_invalid"))
      .trim(),
    billing_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Billing pincode" }))
      .min(4, t("zipcode_length_invalid"))
      .trim(),
    billing_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Billing country" }))
      .trim(),
    delivery_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_address_1.invalid"))
      .min(5, t("required_note"))
      .trim(),
    delivery_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_address_2.invalid"))
      .min(5, t("required_note"))
      .trim(),
    delivery_city: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Delivery city" }))
      .min(3, t("min_length_invalid"))
      .trim(),
    delivery_state: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Delivery state" }))
      .min(2, t("state_length_invalid"))
      .trim(),
    delivery_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Delivery pincode" }))
      .min(4, t("zipcode_length_invalid"))
      .trim(),
    delivery_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("invalid", { value: "Delivery country" }))
      .trim(),
  });

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    // option: (provided, state) => ({
    //   ...provided,
    //   backgroundColor: state.isFocused || state.isSelected ? "#0194ff" : "#fff",
    //   color: state.isFocused || state.isSelected ? "#fff" : "#000",
    //   ":hover": {
    //     backgroundColor: "#0194ff",
    //     color: "#fff",
    //   },
    // }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0194ff"
        : state.isFocused
          ? "#f0f0f0"
          : "transparent",
      color: state.isSelected ? "#fff" : state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    }),
  };

  const handleAddressChanges = () => {
    const billingValues = formRef.current.values;
    const deliveryValues = {
      delivery_primary_address: billingValues.billing_primary_address,
      delivery_secondary_address: billingValues.billing_secondary_address,
      delivery_country: billingValues.billing_country,
      delivery_state: billingValues.billing_state,
      delivery_city: billingValues.billing_city,
      delivery_zipcode: billingValues.billing_zipcode,
    };
    const addressesAreEqual = isEqual(billingValues, deliveryValues);
    setDeliverySameAsBilling(addressesAreEqual);
  };

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");

      // const invalidCodes = [
      //   "93", // Afghanistan (AF)
      //   "375", // Belarus (BY)
      //   "236", // Central African Republic (CF)
      //   "86", // China (CN)
      //   "53", // Cuba (CU)
      //   "243", // Democratic Republic of the Congo (CD)
      //   "251", // Ethiopia (ET)
      //   "91", // India (IN)
      //   "98", // Iran (IR)
      //   "964", // Iraq (IQ)
      //   "218", // Libya (LY)
      //   "223", // Mali (ML)
      //   "505", // Nicaragua (NI)
      //   "850", // North Korea (KP)
      //   "252", // Somalia (SO)
      //   "211", // South Sudan (SS)
      //   "249", // Sudan (SD)
      //   "963", // Syria (SY)
      //   "1", // United States (US)
      //   "58", // Venezuela (VE)
      //   "967", // Yemen (YE)
      //   "263", // Zimbabwe (ZW)
      // ];

      // if (invalidCodes.includes(countryCode)) {
      //   formRef.current.setFieldError("mobile", t("mobile.invalid"));
      //   return;
      // }

      dispatch(
        updateOnboardingDetailsStart({
          ...values,
          dob: values.dob.split("-").reverse().join("-"),
          mobile: mobile,
          mobile_country_code: countryCode,
        })
      );
    }
  };

  const handleCheckboxChange = () => {
    setDeliverySameAsBilling(!deliverySameAsBilling);
    if (!deliverySameAsBilling) {
      // Copy values from billing address to delivery address fields
      const billingAddress = formRef.current.values;
      formRef.current.setValues({
        ...billingAddress,
        delivery_primary_address: billingAddress.billing_primary_address,
        delivery_secondary_address: billingAddress.billing_secondary_address,
        delivery_country: billingAddress.billing_country,
        delivery_state: billingAddress.billing_state,
        delivery_city: billingAddress.billing_city,
        delivery_zipcode: billingAddress.billing_zipcode,
      });
    }
  };

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      const addressValues = formRef.current.values;
      const billingValues = {
        primary_address: addressValues.billing_primary_address,
        secondary_address: addressValues.billing_secondary_address,
        country: addressValues.billing_country,
        state: addressValues.billing_state,
        city: addressValues.billing_city,
        zipcode: addressValues.billing_zipcode,
      };
      const deliveryValues = {
        primary_address: addressValues.delivery_primary_address,
        secondary_address: addressValues.delivery_secondary_address,
        country: addressValues.delivery_country,
        state: addressValues.delivery_state,
        city: addressValues.delivery_city,
        zipcode: addressValues.delivery_zipcode,
      };
      if (isEqual(billingValues, deliveryValues)) {
        setDeliverySameAsBilling(true)
      }
      formRef.current.setFieldValue("occupation", profile.data.user_title);
      formRef.current.setFieldValue("salary_range", profile.data.income_range);
      formRef.current.setFieldValue("employment_type", profile.data.income_source);
    }
  }, [profile]);


  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  maxDate.setDate(maxDate.getDate() - 1);
  maxDate.setHours(23, 59, 59, 999);

  const customFilterOption = (option, inputValue) => {
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  useEffect(() => {
    dispatch(mobileCodeListStart())
    dispatch(nationalitiesListStart({
      all: 1,
    }));
    dispatch(countriesListStart({
      all: 1,
    }))
  }, []);

  useEffect(() => {
    if (!skipRender && !mobileCodeList.buttonDisable && Object.keys(mobileCodeList.data).length > 0) {
      if (mobileCodeList.data.mobile_country_codes.length > 0) {
        setFilteredCountries(mobileCodeList.data.mobile_country_codes)
      }
    }
    setSkipRender(false);
  }, [mobileCodeList])

  useEffect(() => {
    if (!skipRender && !nationalitiesList.buttonDisable && Object.keys(nationalitiesList.data).length > 0) {
      if (nationalitiesList.data.nationalities.length > 0) {
        setNationalitiesOptions(nationalitiesList.data.nationalities.map((item) => ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [nationalitiesList]);

  useEffect(() => {
    if (!skipRender && !countriesList.buttonDisable && Object.keys(countriesList.data).length > 0) {
      if (countriesList.data.countries.length > 0) {
        setCountryOptions(countriesList.data.countries.map((item) => ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [countriesList])


  return (
    <div className="efi-tab-featur-card">
      <div className="efi-bank-billing-address-frame mb-3">
        {profile.data.billing_address && profile.data.delivery_address ? (
          <div className="efi-bank-billing-address-fields">
            <Formik
              initialValues={{
                title: profile.data.title,
                first_name: profile.data.first_name,
                middle_name: profile.data.middle_name || "",
                last_name: profile.data.last_name,
                email: profile.data.email,
                dob: profile.data.dob.split("-").reverse().join("-") || "",
                gender: profile.data.gender,
                nationality: profile.data.nationality,
                mobile:
                  `+${profile.data.mobile_country_code + profile.data.mobile
                  }` || "",
                billing_primary_address:
                  profile.data.billing_address.primary_address || "",
                billing_secondary_address:
                  profile.data.billing_address.secondary_address || "",
                billing_country: profile.data.billing_address.country || "",
                billing_state: profile.data.billing_address.state || "",
                billing_city: profile.data.billing_address.city || "",
                billing_zipcode: profile.data.billing_address.zipcode || "",
                delivery_primary_address:
                  profile.data.delivery_address.primary_address || "",
                delivery_secondary_address:
                  profile.data.delivery_address.secondary_address || "",
                delivery_country: profile.data.delivery_address.country || "",
                delivery_state: profile.data.delivery_address.state || "",
                delivery_city: profile.data.delivery_address.city || "",
                delivery_zipcode: profile.data.delivery_address.zipcode || "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <FORM
                  className="efi-bank-default-form efi-onboarding-form-after-login w-100"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                  }}
                >
                  <Row>
                    <div className="onboarding-auth-header">
                      <h5>{t("personal_information")}</h5>
                    </div>
                    <Col md={6}>
                      <div className="xs-marg-btm">
                        <Row className="gx-1">
                          <Col lg={3} className="resp-mrg-btm-xs">
                            <div className="efi-bank-auth-input-group">
                              <Form.Label className="default-form-label w-100">
                                {t("title.label")} <span>*</span>
                              </Form.Label>
                              <Select
                                styles={customStyles}
                                value={title_options.find(
                                  (item) => (item.value = values.title)
                                )}
                                onChange={(option) => {
                                  setFieldValue("title", option.value);
                                }}
                                className={`${errors.title && touched.title
                                  ? "input-group-error css-1l8uzyq-container"
                                  : ""
                                  }`}
                                options={title_options}
                                name="title"
                                placeholder={<>Title</>}
                                isSearchable={false}
                                isDisabled={profile.data.onboarding >= 4}
                              />
                              <ErrorMessage
                                component={"div"}
                                name="title"
                                className="errorMsg"
                              />
                            </div>
                          </Col>
                          <Col lg={9}>
                            <div className="efi-bank-auth-input-group">
                              <Form.Label className="default-form-label w-100">
                                {t("first_name")} <span>*</span>
                              </Form.Label>
                              <InputGroup
                                className={`${errors.first_name && touched.first_name
                                  ? "input-group-error name"
                                  : ""
                                  }`}
                              >
                                <InputGroup.Text>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#c3c7ca"
                                      d="M12 12a5 5 0 100-10 5 5 0 000 10z"
                                    ></path>
                                    <path
                                      fill="#171717"
                                      d="M12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5z"
                                    ></path>
                                  </svg>
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  name="first_name"
                                  type="text"
                                  placeholder="First Name"
                                  aria-label="text"
                                  aria-describedby="text-addon"
                                  maxLength={16}
                                  disabled={profile.data.onboarding >= 4}
                                />
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="first_name"
                                className="errorMsg"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="xs-marg-btm efi-bank-auth-input-group">
                        <Row className="g-1">
                          <Col lg={6} className="resp-mrg-btm-xs name">
                            <Form.Label className="default-form-label w-100">
                              {t("middle_name")}
                            </Form.Label>
                            <InputGroup
                              className={`${errors.middle_name && touched.middle_name
                                ? "input-group-error name"
                                : ""
                                }`}
                            >
                              <InputGroup.Text>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="#c3c7ca"
                                    d="M12 12a5 5 0 100-10 5 5 0 000 10z"
                                  ></path>
                                  <path
                                    fill="#171717"
                                    d="M12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5z"
                                  ></path>
                                </svg>
                              </InputGroup.Text>
                              <Field
                                className="form-control"
                                name="middle_name"
                                type="text"
                                placeholder="Middle Name"
                                aria-label="text"
                                aria-describedby="text-addon"
                                maxLength={16}
                                disabled={profile.data.onboarding >= 4}
                              />
                            </InputGroup>
                            <ErrorMessage
                              component={"div"}
                              name="middle_name"
                              className="errorMsg"
                            />
                          </Col>
                          <Col lg={6}>
                            <div className="efi-bank-auth-input-group">
                              <Form.Label className="default-form-label w-100">
                                {t("last_name.label")} <span>*</span>
                              </Form.Label>
                              <InputGroup
                                className={`${errors.last_name && touched.last_name
                                  ? "input-group-error name"
                                  : ""
                                  }`}
                              >
                                <InputGroup.Text>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="#c3c7ca"
                                      d="M12 12a5 5 0 100-10 5 5 0 000 10z"
                                    ></path>
                                    <path
                                      fill="#171717"
                                      d="M12 14.5c-5.01 0-9.09 3.36-9.09 7.5 0 .28.22.5.5.5h17.18c.28 0 .5-.22.5-.5 0-4.14-4.08-7.5-9.09-7.5z"
                                    ></path>
                                  </svg>
                                </InputGroup.Text>
                                <Field
                                  className="form-control"
                                  name="last_name"
                                  type="text"
                                  placeholder="Last Name"
                                  aria-label="text"
                                  aria-describedby="text-addon"
                                  maxLength={16}
                                  disabled={profile.data.onboarding >= 4}
                                />
                              </InputGroup>
                              <ErrorMessage
                                component={"div"}
                                name="last_name"
                                className="errorMsg"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="mb-3 efi-bank-auth-input-group">
                        <Form.Label className="default-form-label w-100">
                          {t("email_title")} <span>*</span>
                          {/* {profile.data.onboarding >= 4 ? <span> This value can't be changed</span> : null} */}
                        </Form.Label>
                        <InputGroup
                          className={`${errors.email && touched.email
                            ? "input-group-error"
                            : ""
                            }`}
                        >
                          <InputGroup.Text>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#c3c7ca"
                                d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M12 12.87c-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 01.93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79z"
                              ></path>
                            </svg>
                          </InputGroup.Text>
                          <Field
                            className="form-control"
                            name="email"
                            type="email"
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="email-addon"
                            disabled={profile.data.onboarding >= 4}
                          />
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="errorMsg"
                        />
                      </div>
                      <div className="mb-3 efi-bank-auth-input-group">
                        <Form.Label className="default-form-label w-100">
                          Occupation <span>*</span>
                          {/* {profile.data.onboarding >= 4 ? <span> This value can't be changed</span> : null} */}
                        </Form.Label>
                        <Field
                          className="form-control"
                          name="occupation"
                          type="text"
                          placeholder="Occupation"
                          aria-label="Email"
                          aria-describedby="email-addon"
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="occupation"
                          className="errorMsg"
                        />
                      </div>
                      <div className="mb-3 efi-bank-auth-input-group">
                        <Form.Label className="default-form-label w-100">
                          Income Range <span>*</span>
                          {/* {profile.data.onboarding >= 4 ? <span> This value can't be changed</span> : null} */}
                        </Form.Label>
                        <Field
                          className="form-control"
                          name="salary_range"
                          type="number"
                          placeholder="Income Range"
                          aria-label="Email"
                          aria-describedby="email-addon"
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="salary_range"
                          className="errorMsg"
                        />
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("gender")} <span>*</span>
                        </Form.Label>
                        <div className="gender-check-box">
                          <div className="pretty p-default p-round">
                            <input
                              type="radio"
                              name="flexRadioDefault"
                              id="male"
                              checked={values.gender == "M"}
                              onChange={() => setFieldValue("gender", "M")}
                              disabled={profile.data.onboarding >= 4}
                            />
                            <div className="state p-primary-o">
                              <label> {t("male")}</label>
                            </div>
                          </div>

                          <div className="pretty p-default p-round">
                            <input
                              type="radio"
                              name="flexRadioDefault"
                              id="female"
                              checked={values.gender == "F"}
                              onChange={() => setFieldValue("gender", "F")}
                              disabled={profile.data.onboarding >= 4}
                            />
                            <div className="state p-primary-o">
                              <label> {t("female")}</label>
                            </div>
                          </div>
                          <div className="pretty p-default p-round">
                            <input
                              type="radio"
                              name="flexRadioDefault"
                              id="other"
                              checked={values.gender == "O"}
                              onChange={() => setFieldValue("gender", "O")}
                              disabled={profile.data.onboarding >= 4}
                            />
                            <div className="state p-primary-o">
                              <label> {t("others")}</label>
                            </div>
                          </div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="gender"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3 efi-bank-auth-input-group">
                        <Form.Label className="default-form-label w-100">
                          {t("mobile.label")} <span>*</span>
                          {/* {profile.data.onboarding >= 4 ? <span> This value can't be changed</span> : null} */}
                        </Form.Label>
                        <InputGroup
                          className={`personal-information-form form-control`}
                        >
                          <PhoneInput
                            name="mobile"
                            value={values.mobile}
                            placeholder={t("mobile.placeholder")}
                            onChange={(value) => {
                              setFieldValue("mobile", value);
                            }}
                            // onBlur={() => formRef.current.submitForm()}
                            international
                            countries={mobileCodeList?.data?.mobile_country_codes || []}
                            className={`${touched.mobile && errors.mobile
                              ? "is-invalid"
                              : ""
                              }`}
                            // maxLength={17}
                            disabled={profile.data.onboarding >= 4}
                          />
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="mobile"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("dob.name")} <span>*</span>
                        </Form.Label>
                        <div className="single-date-picker">
                          <InputGroup className="mb-0">
                            <DatePicker
                              minDate={new Date("1900-01-01")}
                              maxDate={maxDate}
                              value={values.dob}
                              onChange={(e) => {
                                setFieldValue(
                                  "dob",
                                  e
                                    ? `${e.day < 10 ? "0" + e.day : e.day}-${e.month < 10 ? "0" + e.month : e.month
                                    }-${e.year}`
                                    : ""
                                );
                                setClose(true);
                              }}
                              onOpen={() =>
                                setFieldValue(
                                  "dob",
                                  values.dob
                                    ? values.dob
                                    : new Date(maxDate)
                                      .toISOString()
                                      .split("T")[0]
                                      .toString()
                                      .split("-")
                                      .reverse()
                                      .join("-")
                                )
                              }
                              placeholder={t("dob.placeholder")}
                              width="100%"
                              format="DD-MM-YYYY"
                              id="date-picker-input"
                              editable={false}
                              onOpenPickNewDate={false}
                              onFocus={(e) => e.target.blur()}
                              className="hide-caret"
                              disabled={profile.data.onboarding >= 4}
                            ></DatePicker>

                            <InputGroup.Text
                              onClick={() => {
                                const input =
                                  document.getElementById("date-picker-input");
                                if (input) {
                                  input.focus();
                                }
                              }}
                            >
                              {close ? (
                                <>
                                  <button
                                    type="button"
                                    className="search-btn date-cancel"
                                    onClick={() => {
                                      setFieldValue("dob", "");
                                      setClose(false);
                                    }}
                                  >
                                    <svg
                                      height="20"
                                      width="20"
                                      viewBox="0 0 20 20"
                                      aria-hidden="true"
                                      focusable="false"
                                      class="css-tj5bde-Svg"
                                    >
                                      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                    </svg>
                                  </button>
                                </>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="none"
                                  viewBox="0 0 18 18"
                                >
                                  <g clipPath="url(#clip0_47_6)">
                                    <path
                                      fill="#8F9899"
                                      d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_47_6">
                                      <path
                                        fill="#fff"
                                        d="M0 0H18V18H0z"
                                      ></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="dob"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label w-100">
                          {t("nationality.name")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={nationalitiesOptions}
                          styles={customStyles}
                          filterOption={customFilterOption}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) =>
                            setFieldValue("nationality", selectedOption.value)
                          }
                          value={countryOptions.find(
                            (option) => option.value === values.nationality
                          )}
                          isSearchable={false}
                          placeholder={t("nationality.placeholder")}
                          isDisabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="nationality"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <div className="mb-3 efi-bank-auth-input-group">
                        <Form.Label className="default-form-label w-100">
                          Employment Type <span>*</span>
                          {/* {profile.data.onboarding >= 4 ? <span> This value can't be changed</span> : null} */}
                        </Form.Label>
                        <Field
                          className="form-control"
                          name="employment_type"
                          type="text"
                          placeholder="Employment Type"
                          aria-label="Email"
                          aria-describedby="email-addon"
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="employment_type"
                          className="errorMsg"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="onboarding-auth-header">
                        <h5>{t("billing_address")}</h5>
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("billing_address_1.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("billing_address_1.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="billing_primary_address"
                          onInput={handleAddressChanges}
                          maxLength={30}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="billing_primary_address"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("billing_address_2.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("billing_address_2.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="billing_secondary_address"
                          onInput={handleAddressChanges}
                          maxLength={30}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="billing_secondary_address"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("billing_country")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={countryOptions}
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "billing_country",
                              selectedOption.value
                            );
                            handleAddressChanges();
                          }}
                          isDisabled={profile.data.onboarding >= 4}
                          isSearchable={false}
                          value={countryOptions.find(
                            (option) => option.value === values.billing_country
                          )}
                        />
                        <ErrorMessage
                          name="billing_country"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("billing_state.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("billing_state.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="billing_state"
                          onInput={handleAddressChanges}
                          maxLength={20}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="billing_state"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("billing_city.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("billing_city.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="billing_city"
                          onInput={handleAddressChanges}
                          maxLength={20}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="billing_city"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("billing_pincode.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("billing_pincode.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="billing_zipcode"
                          onInput={handleAddressChanges}
                          maxLength={7}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="billing_zipcode"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <div className="onboarding-auth-header d-flex align-items-center justify-content-between">
                        <h5> {t("delivery_address")}</h5>
                        <Checkbox
                          checked={deliverySameAsBilling}
                          onChange={handleCheckboxChange}
                          disabled={deliverySameAsBilling || profile.data.onboarding >= 4}
                          icon={
                            <Image
                              src={
                                window.location.origin + "/img/tick-mark.svg"
                              }
                              className="tick-mark-checkbox"
                            />
                          }
                        >
                          {t("same_as_billing_addres")}
                        </Checkbox>
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("delivery_address_1.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("delivery_address_1.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="delivery_primary_address"
                          onInput={handleAddressChanges}
                          maxLength={30}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="delivery_primary_address"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("delivery_address_2.name")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("delivery_address_2.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="delivery_secondary_address"
                          onInput={handleAddressChanges}
                          maxLength={30}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="delivery_secondary_address"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("delivery_country")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={countryOptions}
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "delivery_country",
                              selectedOption.value
                            );
                            handleAddressChanges();
                          }}
                          isSearchable={false}
                          value={countryOptions.find(
                            (option) => option.value === values.delivery_country
                          )}
                          isDisabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="delivery_country"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("delivery_state")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("delivery_state.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="delivery_state"
                          onInput={handleAddressChanges}
                          maxLength={20}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="delivery_state"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("delivery_city")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("billing_city.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="delivery_city"
                          onInput={handleAddressChanges}
                          maxLength={20}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="delivery_city"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          {t("delivery_pincode")} <span>*</span>
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder={t("billing_pincode.placeholder")}
                          aria-label="Name"
                          aria-describedby="text-addon"
                          name="delivery_zipcode"
                          onInput={handleAddressChanges}
                          maxLength={7}
                          disabled={profile.data.onboarding >= 4}
                        />
                        <ErrorMessage
                          name="delivery_zipcode"
                          component="div"
                          className="errorMsg"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {profile.data.onboarding < 4 &&
                    <div className="onboarding-auth-footer">
                      <div className="space-tow"></div>
                      <div className="space-tow">
                        <Button
                          className="efi-primary-btn w-100"
                          type="submit"
                          disabled={updateOnboardingDetails.buttonDisable || profile.data.onboarding == 4}
                        >
                          {updateOnboardingDetails.buttonDisable ? (
                            <ButtonLoader varient="black" />
                          ) : (
                            t("update")
                          )}
                        </Button>
                      </div>
                    </div>}
                </FORM>
              )}
            </Formik>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default IndividualAccountDetails;
