import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  businessDocumentsInfoStart,
  businessDocumentsStatusStart,
  updateBusinessDocumentStart,
  updateBusinessDocumentSuccess,
} from "../../../store/slices/BusinessSlice";
import { getErrorNotificationMessage, getSuccessNotificationMessage } from "../../Helper/NotificationMessage";
import * as Yup from "yup";
import { ButtonLoader } from "../../Helper/Loader";
import NoDataFound from "../../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import configuration from "react-global-configuration";
import { Image, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";

import "@djthoms/pretty-checkbox";
import DatePicker, { DateObject } from "react-multi-date-picker";

import 'react-datepicker/dist/react-datepicker.css';
import { countryListStart, pingPongFieldListStart } from "../../../store/slices/BeneficiariesSlice";
import { values } from "lodash";
import { nationalitiesListStart, pinpPongKybVerifyStart } from "../../../store/slices/AdminSlice";

const KycManual = (props) => {

  const navigate = useNavigate();
  const businessInfoUpdate = useSelector((state) => state.business.businessInfoUpdate);
  // const businessDocumentsStatus = useSelector(state => state.business.businessDocumentsStatus)
  const [certificates, setCertificates] = useState([]);
  const t = useTranslation("kyc_manual");
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const updateBusinessDocument = useSelector((state) => state.business.updateBusinessDocument);
  const businessDocumentsInfo = useSelector((state) => state.business.businessDocumentsInfo);
  const businessDocumentsStatus = useSelector(state => state.business.businessDocumentsStatus)
  const [formStep, setFormStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [skipRender, setSkipRender] = useState(true);
  const [skip, setSkip] = useState([]);
  const formRef = useRef(null);
  const formFieldRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationSchema, setValidationSchema] = useState(null);
  const [field, setField] = useState([]);
  const [taxIdType, setTaxIdType] = useState([]);
  const [registrationType, setRegistrationType] = useState([]);
  const [idType, setIdType] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const nationalitiesList = useSelector((state) => state.admin.nationalitiesList);
  const pinpPongKybVerify = useSelector((state) => state.admin.pinpPongKybVerify);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [merchantAck] = useState([{ label: "Yes", value: "YES" }, { label: "No", value: "NO" }]);
  const [count, setCount] = useState(0);
  const [category, setCategory] = useState([]);
  const [fileValue, setFileValue] = useState("");
  const [loading, setLoading] = useState("");
  const [formField, setFormField] = useState({});




  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/pdf": [".pdf"],
        "image/jpg": [".jpg"],
        "image/png": [".png"],
      },
      maxFiles: 1,
      useFsAccessApi: false,
      onDrop: (acceptedFiles, fileRejections) => {
        // if (fileRejections.length > 0) {
        //   const errors = fileRejections[0].errors;
        //   if (errors.some(error => error.code === "file-invalid-type")) {
        //     setErrorMessage("Only PDF files are allowed.");
        //   }
        // } else {
        //   setErrorMessage("");
        // }
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      // onDragEnter: () => {
      //   setShowMessage(true);
      // },
      // onDragLeave: () => {
      //   setShowMessage(false);
      // },
    });

  const validationDocumaentSchema = Yup.object().shape({
    file: Yup.mixed().required("Please select a file"),
  });

  const handleUpload = () => {
    if (files.length > 0 && files[0].size / 1024 > 4096) {
      getErrorNotificationMessage(
        `Please select file ${files.length > 0 ? `with size less than 4 MB` : ""
        }`
      );
      return;
    }
    dispatch(
      updateBusinessDocumentStart({
        document: files[0],
        document_type: certificates[step - 1].value,
      })
    );
  };

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    setFiles([]);
    formRef.current?.setFieldValue("file", null);
  }, [step]);

  useEffect(() => {
    dispatch(businessDocumentsStatusStart())
    dispatch(
      nationalitiesListStart({
        all: 1,
      })
    );
    dispatch(updateBusinessDocumentSuccess({}))
  }, []);

  useEffect(() => {
    if (!skipRender && !businessDocumentsStatus.buttonDisable && Object.keys(businessDocumentsStatus.data).length > 0 && Object.keys(profile.data).length > 0) {
      switch (businessDocumentsStatus.data.user.iban_kyc_status) {
        case 1:
          if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
            dispatch(businessDocumentsInfoStart());
          }
          break
        case 2:
          setFormStep(2)
          break
        case 3:
          props.setStep(props.step + 1)
          break
        default:
          if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
            dispatch(businessDocumentsInfoStart());
          }
      }

    }
    setSkipRender(false);
  }, [businessDocumentsStatus, profile]);

  useEffect(() => {
    if (!skipRender && !nationalitiesList.buttonDisable && Object.keys(nationalitiesList.data).length > 0) {
      if (nationalitiesList.data.nationalities.length > 0) {
        setCountryCodeList(nationalitiesList.data.nationalities.map((item) => ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [nationalitiesList]);

  useEffect(() => {
    if (
      !skipRender && !Object.keys(updateBusinessDocument.data).length > 0 &&
      !businessDocumentsInfo.buttonDisable &&
      Object.keys(businessDocumentsInfo.data).length > 0
    ) {
      if (formStep == 1) {
        setAccountType(Object.entries(businessDocumentsInfo.data.account_usages).map(([key, value]) => ({
          label: value,
          value: key
        })))
        generateValidationSchema(businessDocumentsInfo.data.rules)
      }
      setCertificates(businessDocumentsInfo.data.documents_list.map((item, index) => ({
        id: index + 1,
        label: item.label,
        value: item.documentType,
        supported: item.documentFileAccepted,
        file_id: businessDocumentsInfo.data.kyb_documents.filter((data) => data.document_type == item.documentType)[0]?.file_id || ""
      })));
      setSkip([])
      setStep(businessDocumentsInfo.data.kyb_documents.length + 1);

    }
    setSkipRender(false);
  }, [businessDocumentsInfo]);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #dbdbdb!important",
      borderRadius: "6px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff"
      },
    }),
  };

 const customStylesMulti={
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    left: "0px",
    borderRadius: "8px",
    overflow: "hidden",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    minWidth: 250,
    fontSize: "0.85em",
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "3px",
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "3px",
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
      backgroundColor: "#555",
    },
  }),
  container: (provided) => ({ ...provided, width: "auto" }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#fff!important",
    border: "1px solid #dbdbdb!important",
    borderRadius: "6px!important",
    boxShadow: "none!important",
    // height: "45px",
    display: "flex",
    alignItems: "center",
    alignItemsContent: "center",
    cursor: "pointer",
    fontSize: "0.9em",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#757575",
    fontSize: "0.9em",
    fontWeight: "400",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000",
    display: "flex",
    alignItems: "center",
    gap: "0.5em",
    fontSize: "0.9em",
    fontWeight: "300",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: "#0194ff!important",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "#757575",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
    color: state.isFocused || state.isSelected ? '#fff' : '#000',
    ":hover": {
      backgroundColor: "#0194ff",
      color: "#fff"
    },
  }),
};

  const generateValidationSchema = (data) => {
    let schema = {};
    let field = [{ label: "location", value: profile.data.user_business.tax_id_issuance_country, isRequired: true, isEnterprise: true, isBusiness: false, type: "select" }, { label: "merchant_ack", isRequired: true, isEnterprise: true, isBusiness: false, type: "select" }, { label: "merchant_type", value: "ENTERPRISE", isRequired: true, isEnterprise: true, isBusiness: false, type: "text" }]
    const documentFields = businessDocumentsInfo.data.documents_list.map(doc => doc.documentType);
    let conditions = ["date_format", "before:", "after:"]
    Object.entries(data.company.enterprise).forEach(([key, value], index) => {
      let data = {}
      const rule = value.split("|");
      let validation = Yup.string();
      data["label"] = key
      data["isEnterprise"] = true
      data["isBusiness"] = false

      rule.forEach((ruleItem) => {
        if (ruleItem.includes("required_without_all")) {
          const nestedFields = ruleItem.split(":")[1]?.split(",").map((field) =>
            field.replace(/company\.enterprise\./g, "").trim()
          ) || [];
          const formattedFields = nestedFields.map(field =>
            field.replace(/_/g, " "))
          validation = validation.test(
            'is-optional',
            `At least one of ${formattedFields.join(" or ")} is required`,
            (value) => {
              return nestedFields.some((nestedField) => formFieldRef.current?.values[nestedField] !== "" || value)
            }
          );
        } else
          if (ruleItem === "required") {
            validation = validation.required(`${key.split("_").join(" ")} is required`).test("only spaces not allowed", `only spaces not allowed`, (value) => {
              return value?.trim()?.length > 0
            });
            data["isRequired"] = true
          }
        if (ruleItem.includes("max:")) {
          const maxLength = parseInt(ruleItem.split(":")[1], 10);
          validation = validation.max(maxLength, `${key.split("_").join(" ")} exceeds maximum length of ${maxLength}`);
        }
        if (key == "business_proof_url") {
          validation = validation.url('Invalid URL format')
        }

        if (ruleItem.includes("in:")) {
          const mappedData = ruleItem.split(":").length >= 1
            ? ruleItem.split(":").slice(1).join(",").split(",").map((data) => ({
              label: data.split("_").join(" "),
              value: data
            }))
            : ruleItem.split(":")[1];

          switch (key) {
            case "tax_id_type":
              setTaxIdType(mappedData)
              break;
            case "business_type":
              data["value"] = mappedData[0]?.value
              break;
            case "certificate_of_registration_type":
              setRegistrationType(ruleItem.split(":").slice(1).join(",").split(",").map((data) => ({
                label: data.split("_").join(" "),
                value: data
              })))
              break;

          }
          data["type"] = "select"
          // const validValues = ruleItem.split(":")[1].split(",");
          // validation = validation.oneOf(validValues, `${key} must be one of: ${validValues.join(", ")}`);
        }
        if (conditions.some(condition => ruleItem.includes(condition))) {
          data["type"] = "date"
          const dateFormat = ruleItem.split(":")[1];
          switch (key) {
            case "establishment_date ":
              validation = Yup.date()
                .required("Establishment  date is required")
                .max(new Date(), "Establishment Date  cannot be in the future");
              break;

          }
        }
        else if (ruleItem === "array") {
          data["type"] = "multi"
          validation = Yup.array().of(Yup.string().required('Each value is required')).required('At least one value is required');
        } else if (!ruleItem.includes("in:")) {
          if (key == "products_and_services_category") {
            setCategory(Object.entries(businessDocumentsInfo.data.product_service_types).map(([key, val]) => ({ label: val, value: key })))
            data["type"] = "select"
          }
          else if (documentFields.includes(key)) {
            data["type"] = "image"
          }
          else {
            if (!["id_issue_date", "id_expiry_date", "establishment_date", "expiry_date"].includes(key)) {
              data["type"] = "text"
              if (!["share_proportion", "business_proof_description", "business_proof_url"].includes(key)) {
              validation = validation.test("min 2 characters", `min 2 characters`, (value) => { return value?.length >= 2 }
              ).test("max 50 characters", `max 50 characters`, (value) => {
                return value?.length <= 50
              })
            }

            }

          }

        }
      });
      field.push(data)
      schema[key] = validation;
    });
    let roleType = ""

    data.company.business_person.forEach((person, index) => {
      Object.entries(person).forEach(([key, value]) => {
        let data = {}

        data["label"] = `${key + index}`;
        data["isEnterprise"] = false;
        data["isBusiness"] = true;
        data["index"] = index;
        const rule = value.split("|");
        let validation = Yup.string();

        rule.forEach((ruleItem) => {
          if (ruleItem.includes("required_without_all")) {

            const nestedFields = ruleItem.split(":")[1]?.split(",").map((field) =>
              field.replace(/company\.enterprise\./g, "").trim()
            ) || [];
            const formattedFields = nestedFields.map(field =>
              field.replace(/_/g, " ")
            );
            validation = validation.test(
              'is-optional',
              `At least one of ${formattedFields.join(" or ")} is required`,
              (value) => {
                return nestedFields.some((nestedField) => formFieldRef.current?.values[`${nestedField + index}`] !== "" || value)
              }
            );
          } else if (ruleItem.includes("required_if")) {
            const dataS = ruleItem.split(",")
            const omittedvalue = dataS.slice(1)
            const values = dataS.join(".").split(".")[3]
            if (dataS[0].includes(values)) {
              if (omittedvalue.includes(roleType)) {
                validation = validation.required(`${key.split("_").join(" ")} is required`);
              }
              else {
                validation = validation.nullable()
              }
            }
          } else if (ruleItem.includes("required_unless")) {
            const dataS = ruleItem.split(",")
            const omittedvalue = dataS.slice(1)
            const values = dataS.join(".").split(".")[3]
            if (dataS[0].includes(values)) {
              validation = validation.when(`${values + index}`, {
                is: (val) =>
                  val !== omittedvalue[0],
                then: validation.required(`${key.split("_").join(" ")} is required `).min(0).max(100),
                otherwise: validation.nullable(),
              })
            }
          } else {
            if (ruleItem == "required") {
              data["isRequired"] = true
              validation = validation.required(`${key.split("_").join(" ")} is required`).test("only spaces not allowed", `only spaces not allowed`, (value) => {
                return value?.trim()?.length > 0
              });
            }
          }

          if (ruleItem.includes("max:")) {
            const maxLength = parseInt(ruleItem.split(":")[1], 10);
            validation = validation.max(maxLength, `${key.split("_").join(" ")} exceeds maximum length of ${maxLength}`);
          }
          if (ruleItem === "string") {
            // validation = validation.string(); // This will work if it's a string field
          }
          if (ruleItem.includes("in:")) {
            const mappedData = ruleItem.split(":").length >= 1
              ? ruleItem.split(":").slice(1).join(",").split(",").map((data) => ({
                label: data.split("_").join(" "),
                value: data
              }))
              : ruleItem.split(":")[1];

            switch (key) {
              case "id_type":
                setIdType(mappedData)
                break;
              case `role_type`:
                data["value"] = mappedData[0]?.value
                roleType = mappedData[0]?.value
                break;


            }
            data["type"] = "select"
            const validValues = ruleItem.split(":")[1].split(",");
            if (key == "share_proportion") {
              data["isRequired"] = true
              validation = validation.required(`${key.split("_").join(" ")} is required`);
              validation = validation.test("share validation", `${key.split("_").join(" ")} must be 0 to 100`, (val) => {
                return val >= 0 && val <= 100
              });
            }

          }
          if (conditions.some(condition => ruleItem.includes(condition))) {
            data["type"] = "date"
            const dateFormat = ruleItem.split(":")[1];
            switch (key) {
              case "date_of_birth":
                validation = Yup.date()
                  .required("Date of birth is required")
                  .test("age", "You must be at least 18 years old", (value) => {
                    const today = new Date();
                    const dob = new Date(value);
                    const age = today.getFullYear() - dob.getFullYear();
                    const m = today.getMonth() - dob.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
                      return age > 18;
                    }
                    return age >= 18;
                  });
                break;

            }
          }
          else if (!ruleItem.includes("in:")) {
            switch (key) {
              case "country":
              case "country_of_birth":
              case "nationality":
                data["type"] = "select"
                break;
              default:
                if (documentFields.includes(key)) {
                  data["type"] = "image"
                }
                else {
                  if (!["id_issue_date", "id_expiry_date", "date_of_birth"].includes(key)) {
                    data["type"] = "text"
                    if (!["share_proportion", "business_proof_description", "business_proof_url"].includes(key)) {
                    validation = validation.test("min 2 characters", `min 2 characters`, (value) => { return value?.length >= 2 }
                    ).test("max 50 characters", `max 50 characters`, (value) => {
                      return value?.length <= 50
                    })
                  }
                  }
                }

                break;
            }

          }

        });
        data["isBusinessObject"] = index
        field.push(data)
        schema[`${key + index}`] = validation;
      });
    });
    let fieldValues = {}
    field.forEach((data) => {
      const { label, isRequired, value } = data;
      // if (label !== "business_type") {
      fieldValues = {
        ...fieldValues,
        [label]: value || "",
      };
      // }

    });
    schema["location"] = Yup.string().required(` Required`)
    schema["merchant_ack"] = Yup.string().required(` Required`)
    schema["merchant_type"] = Yup.string().required(` Required`)
    formFieldRef.current?.setValues(fieldValues)
    setField(field)
    setValidationSchema(Yup.object().shape(schema));

  };

  const formatdata = () => {
    let enterprise = {}
    Object.entries(formFieldRef.current?.values).forEach(([key, value]) => {
      if (!(key.endsWith("0") || key.endsWith("1")) && key !== "merchant_ack" && key !== "merchant_type" && key !== "location") {
        Object.entries(businessDocumentsInfo.data?.rules?.company.enterprise).forEach(([keyData, value]) => {

          const documentEnterprise = businessDocumentsInfo.data.kyb_documents.filter((val) => val.document_type == keyData)
          if (documentEnterprise?.length > 0) {
            enterprise[`company[enterprise][${keyData}]`] = documentEnterprise[0]?.file_id
          }
        })
        if (key == "account_usage") {
          value.map((val, i) => {
            enterprise[`company[enterprise][${key}][${i}]`] = val
          })
        }
        else {
          enterprise[`company[enterprise][${key}]`] = value
        }


      }
    })

    let business1 = {}
    Object.entries(formFieldRef.current?.values).forEach(([key, value]) => {
      if (key.includes(["0"])) {
        Object.entries(businessDocumentsInfo.data?.rules?.company.business_person[0]).forEach(([keyData, value]) => {
          const documentEnterprise = businessDocumentsInfo.data.kyb_documents.filter((val) => val.document_type == keyData)
          if (documentEnterprise?.length > 0) {
            business1[`company[business_person][0][${keyData}]`] = documentEnterprise[0]?.file_id
          }
        })
        business1[`company[business_person][0][${key.slice(0, -1)}]`] = value

      }
    })

    let business2 = {}
    Object.entries(formFieldRef.current?.values).forEach(([key, value]) => {
      if (key.includes(["1"])) {
        Object.entries(businessDocumentsInfo.data?.rules?.company.business_person[1]).forEach(([keyData, value]) => {
          const documentEnterprise = businessDocumentsInfo.data.kyb_documents.filter((val) => val.document_type == keyData)
          if (documentEnterprise?.length > 0) {
            business2[`company[business_person][1][${keyData}]`] = documentEnterprise[0]?.file_id
          }
        })
        business2[`company[business_person][1][${key.slice(0, -1)}]`] = value

      }
    })
    let business = [business1, business2]
    return ({
      location: formFieldRef.current?.values.location,
      merchant_ack: formFieldRef.current?.values.merchant_ack,
      merchant_type: formFieldRef.current?.values.merchant_type,
      ...business1, ...business2, ...enterprise

    })
  }
  const pingPongKycSubmit = async () => {
    const data = formatdata()
    dispatch(pinpPongKybVerifyStart(data))
  }

  const handleFileChange = (event, value, i) => {
    if(event.target.files[0]){
      console.log("event files", event.target.files[0])
      const selectedFile = event.target.files[0];

      if (!selectedFile) {
        return;
      }

      const validTypes = businessDocumentsInfo.data.documents_list.find((data) => data.documentType == (typeof i !== "string" ? value.slice(0, -1) : value))

      if (!validTypes.documentFileAccepted.includes(selectedFile.type.split("/")[1]?.toUpperCase())) {
        getErrorNotificationMessage(`Please select a ${validTypes?.documentFileAccepted}.`);
        return;
      }

      const maxSize = parseFloat(validTypes?.documentFileSize?.charAt(0)) * 1024 * 1024;
      if (selectedFile.size > maxSize) {
        getErrorNotificationMessage(`File size should be less than ${validTypes?.documentFileSize}.`);
        return;
      }

      setFileValue(value)
      setLoading(value)
      dispatch(
        updateBusinessDocumentStart({
          document: selectedFile,
          document_type: typeof i !== "string" ? value.slice(0, -1) : value,
          upload_for: typeof i !== "string" ? `business_person_${i}` : "enterprise"
        })
      )
    }
  };

  useEffect(() => {
    if (!skipRender && !updateBusinessDocument.loading && Object.keys(updateBusinessDocument.data).length > 0) {
      setLoading("")
      formFieldRef.current.setFieldValue(`${fileValue}`, updateBusinessDocument.data.business_kyb_document.external_reference_id)
      setFileValue("")
    }

  }, [updateBusinessDocument])

  useEffect(() => {
    if (field.length > 0 && businessDocumentsInfo.data?.kyb_documents?.length > 0) {

      businessDocumentsInfo.data.kyb_documents.forEach((data) => {

        const value = data.unique_id.charAt(data.unique_id.length - 1)
        formFieldRef.current?.setFieldValue(isNaN(parseInt(value)) ? data.document_type : `${data.document_type}${value}`, data.file_id)
      })

    }

  }, [field])

  useEffect(() => {
    if (!skipRender && !pinpPongKybVerify.loading && Object.keys(pinpPongKybVerify.data).length > 0) {
      setFormStep(2)
    }
  }, [pinpPongKybVerify])

  const handleCopy = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      getSuccessNotificationMessage("Url Copied")
    }
    catch (e) {
      getErrorNotificationMessage(e)
    }

  }

  return (
    <>
      <div className="onboarding-kyc-manual-sec onboarding-auth pingpong-frame">
        <div className="onboarding-auth-header onboarding-kyc-manual-header-sec resp-mrg-btm-xs">
          <div className="onboarding-auth-titles">
            <h3>{t("heading")}</h3>
            <p>
              {configuration.get("configData.site_name") + t("content")}
            </p>
          </div>
          {formStep == 1 && (
            <Button
              className="efi-overlay-btn"
              onClick={() => navigate("/")}
              disabled={businessInfoUpdate.buttonDisable || updateBusinessDocument.buttonDisable || businessDocumentsInfo.buttonDisable}
            >
              {t("skip_onboarding")}
            </Button>)}
        </div>
        <div className={`onboarding-auth billing-addres-form  ${formStep !== 1 && "display-container"}`}>
          {businessDocumentsInfo.loading || businessDocumentsStatus.loading ? (
            <Row>
              <Skeleton height={40} className="mb-4" width={100} />
              {[...Array(15)].map(item => (
                <Col md={4}>
                  {/* <Skeleton height={120} className="mb-3" width={"100%"} /> */}
                  <Skeleton height={50} className="mb-4" width="100%" />
                </Col>
              ))}
              <Skeleton height={40} className="mb-4" width={100} />
              {[...Array(15)].map(item => (
                <Col md={4}>
                  {/* <Skeleton height={120} className="mb-3" width={"100%"} /> */}
                  <Skeleton height={50} className="mb-4" width="100%" />
                </Col>
              ))}
            </Row>
          ) : Object.keys(businessDocumentsInfo.data).length > 0 ? (
            <Formik
              initialValues={{}}
              validationSchema={validationSchema}
              onSubmit={pingPongKycSubmit}
              innerRef={formFieldRef}
            >
              {({ setFieldValue, values, touched, errors }) => (
                <FORM
                  className="efi-bank-default-form efi-onboarding-form-after-login w-100"
                  style={{ display: "flex", flexDirection: "column", gap: "1em" }}
                >
                  <h4>Enterprise</h4>
                  <Row>
                    {field.map((value, index) => (value.isEnterprise && (
                      value.type == "text" && value.label !== "merchant_type" ?
                        <Col md={4}>
                          <Form.Group className="mb-3">
                            <Form.Label className="default-form-label">
                              {!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)}
                              <span>{value.isRequired && "*"}</span>
                            </Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={t("placeholder", { value: `Enter ${!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`).join('')}` })}
                              aria-describedby="text-addon"
                              name={value.label}
                              // onInput={handleAddressChanges}
                              maxLength={50}
                              disabled={updateBusinessDocument.buttonDisable}
                            />
                            <ErrorMessage
                              name={value.label}
                              component="div"
                              className="errorMsg"
                            />
                          </Form.Group>
                        </Col> :
                        value.type == "select" && value.label !== "business_type" && value.label !== "location" ?
                          <Col md={4}>
                            <Form.Group className="mb-3">
                              <Form.Label className="default-form-label w-100">
                                {!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)} <span>{value.isRequired && "*"}</span>
                              </Form.Label>
                              <Select
                                options={value.label == "location" ? countryCodeList : value.label == "merchant_ack" ? merchantAck : value.label == "tax_id_type" ? taxIdType : value.label == "products_and_services_category" ? category : value.label == "certificate_of_registration_type" && registrationType || []}
                                styles={customStyles}
                                classNamePrefix="react-select"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    value.label,
                                    // iso.whereAlpha2(selectedOption.value).alpha3
                                    selectedOption.value
                                  )
                                }
                                isSearchable
                                // value={countryOptions.find((option) =>
                                //   option.value === values.tax_id_issuance_country
                                //     // ? iso.whereAlpha3(values.tax_id_issuance_country)
                                //     //   ?.alpha2
                                //     ? values.tax_id_issuance_country
                                //     : ""
                                // )}
                                isDisabled={updateBusinessDocument.buttonDisable}
                                placeholder={t("placeholder", { value: `Select ${!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`).join('')}` })}
                              />
                              <ErrorMessage
                                component={"div"}
                                name={value.label}
                                className="errorMsg"
                              />
                            </Form.Group>
                          </Col> : value.type == "multi" ?
                            <Col md={4}>
                              <Form.Group className="mb-3">
                                <Form.Label className="default-form-label w-100">
                                  {!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)} <span>{value.isRequired && "*"}</span>
                                </Form.Label>
                                <Select
                                  options={accountType || []}
                                  styles={customStylesMulti}
                                  classNamePrefix="react-select"
                                  isMulti
                                  isDisabled={updateBusinessDocument.buttonDisable}
                                  name={value.label}
                                  onChange={(selectedOptions) => {
                                    const values = selectedOptions.map(option => option.value);
                                    setFieldValue(value.label, values); // Update Formik's state with selected values
                                  }}
                                  placeholder={t("placeholder", { value: `Select ${!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`).join('')}` })}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name={value.label}
                                  className="errorMsg"
                                />
                              </Form.Group>
                            </Col> :
                            value.type == "date" ?
                              <Col md={4}>
                                <Form.Group className="mb-3">
                                  <Form.Label className="default-form-label w-100">
                                    {!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`)} <span>{value.isRequired && "*"}</span>
                                  </Form.Label>
                                  <div className="single-date-picker">
                                    <InputGroup className="mb-0">
                                      <DatePicker
                                        minDate={
                                          value.label !== "expiry_date" ? new Date(
                                            new Date().setFullYear(
                                              new Date().getFullYear() - 150
                                            )
                                          ) : new Date() - 1
                                        }
                                        maxDate={value.label !== "expiry_date" &&
                                          new Date(
                                            new Date().setHours(23, 59, 59, 999)
                                          )
                                        }
                                        // value={values[value.label]}
                                        onChange={(e) =>
                                          setFieldValue(value.label, e ? `${e.year}-${e.month < 10 ? "0" + e.month : e.month}-${e.day < 10 ? "0" + e.day : e.day}` : "")
                                        }
                                        placeholder={t("placeholder", { value: `Select ${!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`).join('')}` })}
                                        width="100%"
                                        format="DD-MM-YYYY"
                                        id={`date-picker-input-${value.label}`}
                                        popperPlacement="Bottom"
                                        disabled={updateBusinessDocument.buttonDisable}
                                      />
                                      <InputGroup.Text
                                        onClick={() => {
                                          const input = document.getElementById(
                                            `date-picker-input-${value.label}`
                                          );
                                          if (input) {
                                            input.focus();
                                          }
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="18"
                                          fill="none"
                                          viewBox="0 0 18 18"
                                        >
                                          <g clipPath="url(#clip0_47_6)">
                                            <path
                                              fill="#8F9899"
                                              d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                            ></path>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_47_6">
                                              <path fill="#fff" d="M0 0H18V18H0z"></path>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </div>
                                  <ErrorMessage
                                    component={"div"}
                                    name={value.label}
                                    className="errorMsg"
                                  />
                                </Form.Group>
                              </Col> : value.type == "image" && <Col md={4}>
                                <Form.Group controlId="formFileDisabled" className="mb-3">
                                  <Form.Label>{
                                    value.label.split("_").length > 1
                                      ? value.label.split("_").map((word) => ` ${word.charAt(0).toUpperCase() + word.slice(1)}`).join("")
                                      : ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}`.slice(0, -1)
                                  } <span>{value.isRequired && "*"}</span></Form.Label>
                                  <Form.Control disabled={updateBusinessDocument.buttonDisable} type="file" onChange={(e) => handleFileChange(e, value.label, "index")} />
                                  {/* {(values[`${value?.label}`] !== "" && !updateBusinessDocument.buttonDisable) &&
                                    <div className="d-flex justify-content-between">
                                     <p className="text-danger  m-0">Already uploaded:</p>
                                     <p className="text-upload-desc m-0">{values[`${value?.label}`]?.length>10?`${values[`${value?.label}`].slice(0,10)}...${values[`${value?.label}`].slice(-8)}`:values[`${value?.label}`]}</p>
                                    </div>
                                  } */}
                                  {(updateBusinessDocument.buttonDisable && value.label == loading) && (
                                    <div className="text-end mt-1">
                                      <div className="upload-loader"></div>
                                    </div>
                                  )}
                                  <ErrorMessage
                                    component={"div"}
                                    name={value.label}
                                    className="errorMsg"
                                  />
                                </Form.Group>

                              </Col>)))}
                  </Row>
                  {businessDocumentsInfo.data.rules.company.business_person.map((data, index) => (
                    <>
                      <h4>Business ({(values[`role_type${index}`] && values[`role_type${index}`].split("_").join(" ")) || "No Role Type Available"})</h4>
                      <Row>
                        {field.map((value, i) => (value.isBusinessObject == index && (
                          value.type == "text" ?
                            <Col md={4}>
                              <Form.Group className="mb-3">
                                <Form.Label className="default-form-label">
                                  {
                                    value.label.split("_").length > 1
                                      ? value.label.split("_").map((word) => ` ${word.charAt(0).toUpperCase() + word.slice(1)}`).join("").slice(0, -1)
                                      : ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}`.slice(0, -1)
                                  } <span>{value.isRequired && "*"}</span>
                                </Form.Label>
                                <Field
                                  className="form-control"
                                  type="text"
                                  placeholder={t("placeholder", { value: `Enter ${!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}`.slice(0, -1) : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`).join('')}`.slice(0, -1) })}
                                  aria-describedby="text-addon"
                                  name={value.label}
                                  // onInput={handleAddressChanges}
                                  maxLength={50}
                                  disabled={updateBusinessDocument.buttonDisable}
                                />
                                <ErrorMessage
                                  name={value.label}
                                  component="div"
                                  className="errorMsg"
                                />
                              </Form.Group>
                            </Col> :
                            value.type == "select" && value.label !== `role_type${index}` ?
                              <Col md={4}>
                                <Form.Group className="mb-3">
                                  <Form.Label className="default-form-label w-100">
                                    {
                                      value.label.split("_").length > 1
                                        ? value.label.split("_").map((word) => ` ${word.charAt(0).toUpperCase() + word.slice(1)}`).join("").slice(0, -1)
                                        : ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}`.slice(0, -1)
                                    } <span>{value.isRequired && "*"}</span>
                                  </Form.Label>
                                  <Select
                                    options={value.label == `id_type${index}` ? idType : countryCodeList}
                                    styles={customStyles}
                                    classNamePrefix="react-select"
                                    onChange={(selectedOption) =>
                                      setFieldValue(
                                        value.label,
                                        // iso.whereAlpha2(selectedOption.value).alpha3
                                        selectedOption.value
                                      )
                                    }
                                    isSearchable
                                    isDisabled={updateBusinessDocument.buttonDisable}
                                    // value={countryOptions.find((option) =>
                                    //   option.value === values.tax_id_issuance_country
                                    //     // ? iso.whereAlpha3(values.tax_id_issuance_country)
                                    //     //   ?.alpha2
                                    //     ? values.tax_id_issuance_country
                                    //     : ""
                                    // )}
                                    placeholder={t("placeholder", { value: `Select ${!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`).join('')}`.slice(0, -1) })}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name={value.label}
                                    className="errorMsg"
                                  />
                                </Form.Group>
                              </Col> :
                              value.type == "date" ?
                                <Col md={4}>
                                  <Form.Group className="mb-3">
                                    <Form.Label className="default-form-label w-100">
                                      {
                                        value.label.split("_").length > 1
                                          ? value.label.split("_").map((word) => ` ${word.charAt(0).toUpperCase() + word.slice(1)}`).join("").slice(0, -1)
                                          : ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}`.slice(0, -1)
                                      } <span>{value.isRequired && "*"}</span>
                                    </Form.Label>
                                    <div className="single-date-picker">
                                      <InputGroup className="mb-0">
                                        <DatePicker
                                          // minDate={new Date() - 1}
                                          minDate={(value.label == `date_of_birth${index}` || value.label == `id_issue_date${index}`) ?
                                            new Date(
                                              new Date().setFullYear(
                                                new Date().getFullYear() - 100
                                              )
                                            ) : new Date() - 1
                                          }
                                          maxDate={(value.label == `date_of_birth${index}` || value.label == `id_issue_date${index}`) ?
                                            new Date(
                                              new Date().setHours(23, 59, 59, 999)
                                            ) : null
                                          }
                                          // maxDate={new Date()}
                                          // value={values[value.label]}
                                          onChange={(e) =>
                                            setFieldValue(value.label, e ? `${e.year}-${e.month < 10 ? "0" + e.month : e.month}-${e.day < 10 ? "0" + e.day : e.day}` : "")
                                          }
                                          placeholder={t("placeholder", { value: `Select ${!value.label.split("_").length > 1 ? ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}` : value.label.split("_").map((value, i) => ` ${value.charAt(0).toUpperCase() + value.slice(1)}`).join('')}`.slice(0, -1) })}
                                          width="100%"
                                          format="DD-MM-YYYY"
                                          id={`date-picker-input-${value.label}`}
                                          popperPlacement="Bottom"
                                          disabled={updateBusinessDocument.buttonDisable}
                                        />
                                        <InputGroup.Text
                                          onClick={() => {
                                            const input = document.getElementById(
                                              `date-picker-input-${value.label}`
                                            );
                                            if (input) {
                                              input.focus();
                                            }
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            fill="none"
                                            viewBox="0 0 18 18"
                                          >
                                            <g clipPath="url(#clip0_47_6)">
                                              <path
                                                fill="#8F9899"
                                                d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_47_6">
                                                <path fill="#fff" d="M0 0H18V18H0z"></path>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </div>
                                    <ErrorMessage
                                      component={"div"}
                                      name={value.label}
                                      className="errorMsg"
                                    />
                                  </Form.Group>
                                </Col> : value.type == "image" && <Col md={4}>
                                  <Form.Group controlId="formFileDisabled" className="mb-3">
                                    <Form.Label>{
                                      value.label.split("_").length > 1
                                        ? value.label.split("_").map((word) => ` ${word.charAt(0).toUpperCase() + word.slice(1)}`).join("").slice(0, -1)
                                        : ` ${value.label.charAt(0).toUpperCase() + value.label.slice(1)}`.slice(0, -1)
                                    } <span>{value.isRequired && "*"}</span></Form.Label>
                                    <Form.Control type="file" onChange={(e) => handleFileChange(e, value.label, index)} disabled={updateBusinessDocument.buttonDisable} />
                                    {/* {(values[`${value?.label}`] !== "" && !updateBusinessDocument.buttonDisable) && 
                                      <div className="d-flex justify-content-between">
                                      <p className="text-danger  m-0">Already uploaded:</p>
                                      <p className="m-0 text-upload-desc">{values[`${value?.label}`]?.length>10?`${values[`${value?.label}`].slice(0,10)}...${values[`${value?.label}`].slice(-8)}`:values[`${value?.label}`]}</p>
                                      </div>
                                    } */}
                                    <ErrorMessage
                                      component={"div"}
                                      name={value.label}
                                      className="errorMsg"
                                    />
                                    {(updateBusinessDocument.buttonDisable && value.label == loading) && (
                                      <div className="text-end mt-1">
                                        <div className="upload-loader"></div>
                                      </div>
                                    )}
                                  </Form.Group>
                                </Col>
                        )))}
                      </Row>
                    </>))
                  }
                  <hr />
                  <div className="onboarding-auth-footer">
                    <div className="onboarding-auth-footer-skip">
                      <Button
                        className="efi-overlay-btn"
                        onClick={() => props.setStep(props.step - 1)}
                        disabled={businessInfoUpdate.buttonDisable}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();  // Prevent the form submission on Enter key
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#0194ff"
                            d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"
                            data-original="#000000"
                          ></path>
                        </svg>
                        <span>{t("back")}</span>
                      </Button>
                    </div>
                    <div className="space-tow">
                      <Button
                        className="efi-primary-btn"
                        type="submit"
                        disabled={pinpPongKybVerify.buttonDisable}
                      >
                        {pinpPongKybVerify.buttonDisable ? (
                          <ButtonLoader varient="black" />
                        ) : (
                          t("submit")
                        )}
                      </Button>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>) : <NoDataFound />}
        </div>
        {formStep == 2 &&
          <div>
            <Button
              className="efi-overlay-btn"
              onClick={() => navigate("/")}
              disabled={businessInfoUpdate.buttonDisable || updateBusinessDocument.buttonDisable || businessDocumentsInfo.buttonDisable}
            >
              Home
            </Button>
            <Row className="justify-content-center">
              {businessDocumentsStatus.data?.data?.kyb_documents?.liveness?.map((user) => (
                <Col lg={7}>
                  <div className="onboarding-kyc-view-board">
                    <div className="kyc-view-avater">
                      <Image
                        src={
                          window.location.origin + "/img/onbaording_user.png"
                        }
                      />
                    </div>
                    <div className="kyc-view-titles">
                      <h3>{user.role_type}</h3>
                    </div>
                    <Row className="justify-content-center">
                      {user.scope.map((data) => (
                        <Col lg={6}>

                          <div className="kyc-view-info">
                            <h4>First Name:</h4>
                            <p>{data?.first_name}</p>
                          </div>
                          <div className="kyc-view-info">
                            <h4>Last Name:</h4>
                            <p>{data?.last_name}</p>
                          </div>
                          <div className="kyc-view-info">
                            <h4>KYC Url Link:</h4>
                            <p>{`${data.verify_url.slice(0, 10)}...${data.verify_url.slice(-5)}`}
                              <Button onClick={() => handleCopy(data.verify_url)}>

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#1f73b7"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                >
                                  <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                  />
                                  <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                  <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                </svg>
                              </Button>
                            </p>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Col>))}
            </Row>
          </div>}
      </div>
    </>
  );
};
export default KycManual;