import React, { useState, useEffect, useRef, use } from "react";
import { Button, InputGroup, Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { beneficiaryListStart, blockUnblockBeneficiaryStart, deleteBeneficiaryStart } from "../../store/slices/BeneficiariesSlice.jsx";
import Skeleton from "react-loading-skeleton";
import NoDataFoundNew from "../Helper/NoDataFoundNew.jsx";
import { useTranslation } from "react-multi-lang";
import Pagination from "../Helper/Pagination.jsx";
import {
    getErrorNotificationMessage,
    getSuccessNotificationMessage,
} from "../Helper/NotificationMessage.jsx";
import CopyToClipboard from "react-copy-to-clipboard";
import iso from "iso-3166-1";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { Tooltip } from "react-tooltip";
import { ButtonLoader } from "../Helper/Loader.jsx";
import PingPongVirtualView from "./PingPongVirtualAccountView.jsx";
import PingPongVirtualAccountAdd from "./PingPongVirtualAccountAdd.jsx";
import { pingPongVirtualAccountStart } from "../../store/slices/AccountsSlice.jsx";
import NoDataFound from "../Helper/NoDataFound.jsx";

const PingPongVirtualAccount = () => {

    const customStyles = {
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            left: "0px",
            borderRadius: "8px",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            minWidth: 250,
            fontSize: "0.85em",
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "3px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "3px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
                backgroundColor: "#555",
            },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent!important",
            border: "1px solid #E5E8EF!important",
            borderRadius: "8px!important",
            boxShadow: "none!important",
            height: "45px",
            minWidth: "180px",
            cursor: "pointer",
            fontSize: "0.95em",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#757575",
            fontSize: "0.95em",
            fontWeight: "400",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#0194ff",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            fontSize: "0.95em",
            fontWeight: "600",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            color: "#0194ff!important",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#878E96",
            },
        }),
        // option: (provided, state) => ({
        //   ...provided,
        //   backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
        //   color: state.isFocused || state.isSelected ? '#fff' : '#000',
        //   ":hover": {
        //     backgroundColor: "#0194ff",
        //     color: "#fff"
        //   },
        // }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? "#0194ff"
                : state.isFocused
                    ? "#f0f0f0"
                    : "transparent",
            color: state.isSelected ? "#fff" : state.isFocused ? "#000" : "#000",
            ":hover": {
                backgroundColor: "#f0f0f0",
                color: "#000",
            },
        }),
    };
    const [modalShow, setModalShow] = React.useState(false);
    const dispatch = useDispatch();
    const pingPongVirtualAccount = useSelector((state) => state.accounts.pingPongVirtualAccount);

    useEffect(() => {
        dispatch(pingPongVirtualAccountStart({ balance: 1 }));
    }, [])

    return (
        <div className="full-body-card-wrapped">
            <div className="pp-virtual-account-wrapped">
                <div className="pp-virtual-account-action">
                    <div className="payment-table-header justify-content-between">
                        <div className="efi-feature-transation-title">
                            <h2>
                                Accounts
                                <span className="ml-3">
                                    {pingPongVirtualAccount.loading ? 0 : Object.keys(pingPongVirtualAccount.data?.virtual_accounts)?.length}
                                </span>
                            </h2>
                        </div>
                        <div className="efi-feature-transation-search-frame">
                            {/* <Select
                                options={[]}
                                className="select-payout"
                                styles={customStyles}
                                isSearchable={false}
                                placeholder={
                                    <div className="placeholder-flex">
                                        Filter
                                    </div>
                                }
                            />
                            <div className="header-search">
                                <Formik
                                    initialValues={{
                                        from_date: "",
                                        to_date: "",
                                    }}
                                    onSubmit={(val) => {
                                    }}
                                >
                                    {({ values, setFieldValue, resetForm }) => (
                                        <FORM className="form">

                                            <div className="efi-transation-search">
                                                <InputGroup className="mb-0">
                                                    <Field
                                                        placeholder="Search Account"
                                                        type="text"
                                                        className="form-control trans-form-control"
                                                        name="search_key"
                                                    />
                                                    <InputGroup.Text id="basic-addon1">
                                                        <Button
                                                            className="search-btn"
                                                        >
                                                            <svg
                                                            height="20"         //already commented
                                                            width="20"
                                                            viewBox="0 0 20 20"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                                                        >
                                                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                        </svg>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="#333"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                             class="icon icon-tabler icons-tabler-outline icon-tabler-search"  //already commented
                                                            >
                                                                <path
                                                                    stroke="none"
                                                                    d="M0 0h24v24H0z"
                                                                    fill="none"
                                                                />
                                                                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                                                <path d="M21 21l-6 -6" />
                                                            </svg>
                                                        </Button>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </div>
                                        </FORM>
                                    )}
                                </Formik>
                            </div> */}
                            <Button
                                className="efi-primary-btn"
                                onClick={() => setModalShow(true)}
                            >
                                Create Account
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="pp-virtual-account-table">
                    {pingPongVirtualAccount.loading ?

                        <Skeleton count={5} height={60} />

                        : (Object.keys(pingPongVirtualAccount.data).length > 0 && pingPongVirtualAccount.data?.virtual_accounts?.length > 0) ?
                            <Table className="common-table" responsive>
                                <thead className="common-table-header">
                                    <tr>
                                        <th>Country</th>
                                        <th>Currency</th>
                                        <th>Balance</th>
                                        <th>Account Number</th>
                                        <th>Created Date</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {pingPongVirtualAccount.data.virtual_accounts.map((item, index) => (
                                    <tbody className="common-table-body">
                                        <tr >
                                            <td className="account-grid-copy">
                                                {iso.whereAlpha2(item.country)?.country}
                                                <div
                                                    className="my-anchor-element"
                                                // data-tooltip-variant="error"
                                                >
                                                    {/* {item.account_id !== "N/A" &&
                                                        <CopyToClipboard
                                                            text={item.account_id}
                                                            onCopy={() => getSuccessNotificationMessage("Copied!")}
                                                        >
                                                            <Button
                                                                className="copy-btn"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="#1f73b7"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                                                >
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                        fill="none"
                                                                    />
                                                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                                                </svg>
                                                            </Button>
                                                        </CopyToClipboard>} */}
                                                </div>
                                                {/* <Tooltip
                                                    anchorSelect=".my-anchor-element"
                                                    place="bottom"
                                                    className="tooltip-element-box"
                                                >
                                                    copy
                                                </Tooltip> */}
                                            </td>
                                            <td>
                                                {item.currency}
                                            </td>
                                            <td>
                                                {pingPongVirtualAccount.data.virtual_account_balance?.filter((balance) => balance?.currency === item?.currency)[0]?.avail_amount}
                                            </td>
                                            <td className="account-grid-copy">
                                                {item.account_number.length > 15 ? `${item.account_number.slice(0, 5)}...${item.account_number.slice(-5)}` : item.account_number}
                                                <div
                                                    className="my-anchor-element"
                                                // data-tooltip-variant="error"
                                                >
                                                    {item.account_number !== "N/A" &&
                                                        <CopyToClipboard text={item.account_number} onCopy={() => getSuccessNotificationMessage("Copied!")}>
                                                            <Button
                                                                className="copy-btn"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="#1f73b7"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                                                >
                                                                    <path
                                                                        stroke="none"
                                                                        d="M0 0h24v24H0z"
                                                                        fill="none"
                                                                    />
                                                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                                                </svg>
                                                            </Button>
                                                        </CopyToClipboard>}
                                                </div>
                                                <Tooltip
                                                    anchorSelect=".my-anchor-element"
                                                    place="bottom"
                                                    className="tooltip-element-box"
                                                >
                                                    copy
                                                </Tooltip>
                                            </td>
                                            <td>{item.created_at_formatted}</td>
                                            <td >
                                                <div className="payment-completed">
                                                    {item.status}
                                                </div>
                                            </td>
                                            <td
                                                className="view-beneficiary-btn"
                                                style={{
                                                    display: "flex",
                                                    gap: "1em",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <Link
                                                    to={`/virtual-details-pp/${item.unique_id}`}
                                                    className="view efi-overlay-btn btn-action"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlSpace="preserve"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <g fill="#0194ff">
                                                            <path
                                                                d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.91 4.91 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.91 4.91 0 0 0 0-5.162m-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.92 2.92 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.92 2.92 0 0 1 0 3.068"
                                                                data-original="#000000"
                                                            ></path>
                                                            <path
                                                                d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5m0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3"
                                                                data-original="#000000"
                                                            ></path>
                                                        </g>
                                                    </svg>
                                                    {/* {t("view")} */}
                                                </Link>
                                                <Tooltip
                                                    anchorSelect=".view"
                                                    place="bottom"
                                                    className="tooltip-element-box"
                                                >
                                                    view
                                                </Tooltip>

                                            </td>
                                        </tr>
                                    </tbody>))}
                            </Table> : <NoDataFound />}
                </div>
            </div >
            {modalShow &&
                <PingPongVirtualAccountAdd show={modalShow}
                    onHide={() => setModalShow(false)} />}
        </div>
    )
}

export default PingPongVirtualAccount;